import { createSlice } from '@reduxjs/toolkit';

// import utils
import { _t } from '../../../../utils/i18n';

/**
 * Changing theme color
 *
 * The code was taken from https://habr.com/ru/post/659491/
 */
const getTheme = () => {
  const theme = `${ window?.localStorage?.getItem( 'theme' ) }`;
  if ( ['light', 'dark'].includes( theme ) ) return theme;

  const userMedia = window.matchMedia( '(prefers-color-scheme: light)' );

  if ( userMedia.matches ) return 'light';

  return 'dark';
  //return 'light';
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    isCollapsed: false,
    theme: getTheme(),
    activeSection: 'dashboard',
    sectionTitle: _t( 'menu_title_dashboard' ),
    activeDropdown: '',
  },
  reducers: {
    collapseSidebar: ( state, action ) => {
      state.isCollapsed = action.payload
    },
    changeSection: ( state, action ) => {
      const { activeSection, sectionTitle } = action.payload;

      state.activeSection = activeSection;
      state.sectionTitle  = sectionTitle;
    },
    changeActiveDropdown: ( state, action ) => {
      state.activeDropdown = action.payload;
    }
  }
});

export const { collapseSidebar, changeSection, changeActiveDropdown } = sidebarSlice.actions;

export default sidebarSlice.reducer;