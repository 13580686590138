// import utils
import { _t } from '../../../utils/i18n';

// import helpers
import { swissNumberInConfig } from '../../../helpers/swissNumberFormat';

// define icons
const zoomIcon = '<svg xmlns="http://www.w3.org/2000/svg" class="transaction-chart__toolbar__icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#444441" fill="none" stroke-linecap="round" stroke-linejoin="round">' +
  '    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>' +
  '    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"/>' +
  '    <path d="M7 10l6 0"/>' +
  '    <path d="M10 7l0 6"/>' +
  '    <path d="M21 21l-6 -6"/>' +
  '  </svg>';

const zoomInIcon = '<svg xmlns="http://www.w3.org/2000/svg" class="transaction-chart__toolbar__icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#444441" fill="none" stroke-linecap="round" stroke-linejoin="round">' +
  '  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>' +
  '  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />' +
  '  <path d="M9 12l6 0" />' +
  '  <path d="M12 9l0 6" />' +
  '</svg>';

const zoomOutIcon = '<svg xmlns="http://www.w3.org/2000/svg" class="transaction-chart__toolbar__icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#444441" fill="none" stroke-linecap="round" stroke-linejoin="round">' +
  '  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>' +
  '  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />' +
  '  <path d="M9 12l6 0" />' +
  '</svg>';

const panIcon = '<svg xmlns="http://www.w3.org/2000/svg" class="transaction-chart__toolbar__icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#444441" fill="none" stroke-linecap="round" stroke-linejoin="round">' +
  '  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>' +
  '  <path d="M8 13v-7.5a1.5 1.5 0 0 1 3 0v6.5" />' +
  '  <path d="M11 5.5v-2a1.5 1.5 0 1 1 3 0v8.5" />' +
  '  <path d="M14 5.5a1.5 1.5 0 0 1 3 0v6.5" />' +
  '  <path d="M17 7.5a1.5 1.5 0 0 1 3 0v8.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7a69.74 69.74 0 0 1 -.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47" />' +
  '</svg>';

const resetIcon = '<svg xmlns="http://www.w3.org/2000/svg" class="transaction-chart__toolbar__icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#444441" fill="none" stroke-linecap="round" stroke-linejoin="round">' +
  '  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>' +
  '  <path d="M5 12l-2 0l9 -9l9 9l-2 0" />' +
  '  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />' +
  '  <path d="M10 12h4v4h-4z" />' +
  '</svg>';

/**
 * ApexChart options for the area and line chart types
 *
 * @param data | object
 * @param type | string
 * @return object
 */
export function chartConfig( data, type = 'area' ) {
  const getLabels   = data => data?.map( item => item[0] );
  const getValues   = data => data?.map( item => item[1] );
  const borderColor = '#DADEE5';
  const fontFamily  = 'Montserrat, sans-serif';
  const fontSize    = '12px';
  const axisStyles  = {
    colors: '#536074',
    fontSize: fontSize,
    fontFamily: fontFamily,
    fontWeight: 400,
  };

  return {
    series: [{
      name: _t( 'transaction_chart_name' ),
      data: getValues( data ),
    }],
    chart: {
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
      },
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: true,
          zoom: zoomIcon,
          zoomin: zoomInIcon,
          zoomout: zoomOutIcon,
          pan: panIcon,
          reset: resetIcon,
        }
      },
      locales: [{
        name: 'en',
        options: {
          months: [
            _t( 'calendar_january' ),
            _t( 'calendar_february' ),
            _t( 'calendar_march' ),
            _t( 'calendar_april' ),
            _t( 'calendar_may' ),
            _t( 'calendar_june' ),
            _t( 'calendar_july' ),
            _t( 'calendar_august' ),
            _t( 'calendar_september' ),
            _t( 'calendar_october' ),
            _t( 'calendar_november' ),
            _t( 'calendar_december' )
          ],
          shortMonths: [
            _t( 'transaction_chart_january' ),
            _t( 'transaction_chart_february' ),
            _t( 'transaction_chart_march' ),
            _t( 'transaction_chart_april' ),
            _t( 'transaction_chart_may' ),
            _t( 'transaction_chart_june' ),
            _t( 'transaction_chart_july' ),
            _t( 'transaction_chart_august' ),
            _t( 'transaction_chart_september' ),
            _t( 'transaction_chart_october' ),
            _t( 'transaction_chart_november' ),
            _t( 'transaction_chart_december' )
          ],
          days: [
            _t( 'transaction_chart_sunday' ),
            _t( 'transaction_chart_monday' ),
            _t( 'transaction_chart_tuesday' ),
            _t( 'transaction_chart_wednesday' ),
            _t( 'transaction_chart_thursday' ),
            _t( 'transaction_chart_friday' ),
            _t( 'transaction_chart_saturday' )
          ],
          shortDays: [
            _t( 'calendar_sunday' ),
            _t( 'calendar_monday' ),
            _t( 'calendar_tuesday' ),
            _t( 'calendar_wednesday' ),
            _t( 'calendar_thursday' ),
            _t( 'calendar_friday' ),
            _t( 'calendar_saturday' )
          ],
          toolbar: {
            selectionZoom: _t( 'transaction_chart_selection_zoom' ),
            zoomIn: _t( 'transaction_chart_zoom_in' ),
            zoomOut: _t( 'transaction_chart_zoom_out' ),
            pan: _t( 'transaction_chart_pan' ),
            reset: _t( 'transaction_chart_reset' )
          }
        }
      }],
      defaultLocale: 'en'
    },
    colors: ['#7E3BC6'],
    dataLabels: {
      enabled: false
    },
    grid: {
      borderColor: borderColor,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
    },
    stroke: {
      curve: 'smooth',
      lineCap: 'round',
      width: 4,
    },
    tooltip: {
      style: {
        fontSize: fontSize,
        fontFamily: fontFamily
      },
      x: {
        show: true,
        format: 'dd MMM yyyy',
      },
      y: {
        formatter: value => swissNumberInConfig( value, true ),
      }
    },
    xaxis: {
      type: 'datetime',
      categories: getLabels( data ),
      axisBorder: {
        show: true,
        color: '#EDDCFF',
      },
      labels: {
        show: true,
        rotate: 0,
        datetimeUTC: false,
        datetimeFormatter: {
          year: 'yyyy',
          month: 'MMM yyyy',
          day: 'dd MMM yyyy',
          hour: 'HH:mm',
        },
        style: axisStyles,
      },
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      labels: {
        formatter: value => swissNumberInConfig( value, false ),
        style: axisStyles,
      },
      axisBorder: {
        show: true,
        color: borderColor,
      },
    }
  }
}