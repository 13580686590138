import React from 'react';

// import utils
import { _t, _t_byBrowserLang } from '../../../utils/i18n';

export default function UnderConstruction({ location }) {
  return (
    <div className="main__no-support">
      <h2 className="main__no-support__text">
        { location === 'app' ? _t( 'no_responsive_msg' ) : _t_byBrowserLang( 'no_responsive_msg' ) }
      </h2>
    </div>
  );
}