import React, { memo } from 'react';
import { Helmet }      from 'react-helmet';

// import components
import Toast            from '../../elements/Toast/Toast';
import TopStatsContacts from '../../elements/TopStatsContacts/TopStatsContacts';
import PageTitle        from '../../elements/PageTitle/PageTitle';
import ModuleWrapper    from '../../common/ModuleWrapper/ModuleWrapper';
import Table            from '../../elements/Table/Table';
import PopupContacts    from '../../elements/Popup/PopupContacts';

const ContactsContainerWrapper = memo((
  { children, metaTitle, location, statsTitle, pageTitle, pageTitleText, pageDescriptionText, table, tableTitle,
    tableDescription, tableColumns, tableSort, tableExportFields, popup }
  ) => {
  return (
    <div className="container main-container">
      <Helmet>
        <title>{ metaTitle }</title>
      </Helmet>

      <Toast />

      <div className="row">
        <div className="col col-xs-12">
          <TopStatsContacts location={ location }
                            statsTitle={ statsTitle } />
        </div>
      </div>

      { pageTitle ?
        <div className="row">
          <div className="col col-xs-12">
            <PageTitle title={ pageTitleText } description={ pageDescriptionText } />
          </div>
        </div>
      : false }

      { table ?
        <div className="row">
          <div className="col col-xs-12">
            <ModuleWrapper title={ tableTitle }
                           description={ tableDescription }>
              <Table location={ location }
                     defaultSortFieldId={ tableSort }
                     defaultSort="desc"
                     columns={ tableColumns }
                     exportFields={ tableExportFields } />
            </ModuleWrapper>
          </div>
        </div>
      : false }

      { popup ? <PopupContacts location={ location } /> : false }
    </div>
  )
});

export default ContactsContainerWrapper;