import { createSlice } from '@reduxjs/toolkit';

const crowdfundingSlice = createSlice({
  name: 'toast',
  initialState: {
    isShown: false,
    type: 'info',
    text: ''
  },
  reducers: {
    showToast: ( state, action ) => {
      const { isShown, type, text } = action.payload;

      state.isShown = isShown;
      state.type    = type;
      state.text    = text;
    },
    hideToast: ( state, action ) => {
      state.isShown = action.payload;
    }
  }
});

export const { showToast, hideToast } = crowdfundingSlice.actions;

export default crowdfundingSlice.reducer;