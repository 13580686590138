import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart  from 'react-apexcharts';

// import constants
import { SUCCEEDED_STATUS } from '../../../constants/loadingStatuses';
import {
  ALL_DONATIONS_LOCATION,
  MERCHANDISE_LOCATION,
  SHOP_LOCATION,
  ALL_PAYMENT_METHODS_LOCATION,
  POSTFINANCE_PAYMENTS_LOCATION,
  CREDIT_CARD_PAYMENTS_LOCATION,
  EWALLET_PAYMENTS_LOCATION,
  TWINT_PAYMENTS_LOCATION,
  CRYPTO_PAYMENTS_LOCATION,
  PAYPAL_PAYMENTS_LOCATION, INVOICE_PAYMENTS_LOCATION
} from '../../../constants/componentLocation';

// import components
import TopProductsSkeleton from './TopProductsSkeleton';

// import config
import { barConfig } from './config';

const TopProducts = memo( ( { location } ) => {
  let isPaymentMethodsBar = true;

  // get state related to location
  const { status, topProductsChartData } = useSelector( state => {
    switch( location ) {
      case ALL_DONATIONS_LOCATION:
        isPaymentMethodsBar = false;
        return state.allDonations;
      case MERCHANDISE_LOCATION:
        isPaymentMethodsBar = false;
        return state.merchandise;
      case SHOP_LOCATION:
        isPaymentMethodsBar = false;
        return state.shop;
      case ALL_PAYMENT_METHODS_LOCATION:
        return state.allMethods;
      case CREDIT_CARD_PAYMENTS_LOCATION:
        return state.creditCardPayments;
      case EWALLET_PAYMENTS_LOCATION:
        return state.ewalletPayments;
      case TWINT_PAYMENTS_LOCATION:
        return state.twintPayments;
      case POSTFINANCE_PAYMENTS_LOCATION:
        return state.postfinancePayments;
      case CRYPTO_PAYMENTS_LOCATION:
        return state.cryptoPayments;
      case PAYPAL_PAYMENTS_LOCATION:
        return state.paypalPayments;
      case INVOICE_PAYMENTS_LOCATION:
        return state.invoicePayments;
      /* falls through */
      default:
        isPaymentMethodsBar = false;
        return state.allPayments;
    }
  });

  // define the chart options
  const options = barConfig( topProductsChartData, isPaymentMethodsBar );

  return (
    <div className="top-products">
      { status === SUCCEEDED_STATUS ?
        <ReactApexChart options={ options }
                        series={ options.series }
                        type="bar"
                        width="100%"
                        height="350" />
      : <TopProductsSkeleton /> }
    </div>
  )
});

export default TopProducts;