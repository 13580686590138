import React           from 'react';
import { useSelector } from 'react-redux';
import { Link }        from 'react-router-dom';

// import constants
import { ROOT_PATH } from '../../../constants/paths';

// import components
import SidebarExtend from '../SidebarToggle/SidebarExtend';

// import images
import logoDark  from '../../../assets/images/logo-dark-theme.svg'
import logoLight from '../../../assets/images/logo-light-theme.svg'

export default function Logo() {
  // get the global state
  const { theme, isCollapsed } = useSelector( state => state.sidebar );

  return (
    <div className="logo">
      <Link className={ isCollapsed ? 'logo__link logo__link--is-hidden' : 'logo__link' }
            to={ ROOT_PATH }>
        <img className="logo__img"
             src={ theme === 'dark' ? logoDark : logoLight }
             alt="Soulclick Logo" />
      </Link>

      <div className={ isCollapsed ? 'logo__collapse-icon logo__collapse-icon--is-visible' : 'logo__collapse-icon' }>
        <SidebarExtend />
      </div>
    </div>
  )
}