import React, { memo, useEffect }   from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import constants
import { RESEND_POPUP_HIDE } from '../../../constants/dispatchTypes';

//import actions
import { resendInvoice } from '../../../pages/InvoicePayments/api/invoicePaymentsSlice';

// import utils
import { _t } from '../../../utils/i18n';

// import components
import PopupContainer from './PopupContainer';

const PopupResend = memo( () => {
  // get the global state
  const { isShown, orderNumber, paymentMethod } = useSelector( store => store.resendPopup );

  // define dispatch
  const dispatch = useDispatch();

  /**
   * Processing to close the popup
   */
  const handleClose = () => dispatch( { type: RESEND_POPUP_HIDE, payload: false } );

  /**
   * Processing to close the popup if ESC key was pressed
   */
  useEffect(() => {
    if ( isShown ) {
      const closePopupByEsc = e => { if ( e.keyCode === 27 ) handleClose() };

      window.addEventListener( 'keydown', closePopupByEsc );

      return () => window.removeEventListener( 'keydown', closePopupByEsc );
    }
  },[isShown] );

  /**
   * Processing to resend the invoice
   */
  const handleProceed = () => dispatch( resendInvoice( orderNumber, paymentMethod ) );

  return (
    <PopupContainer isShown={ isShown }
                    handleClose={ () => handleClose() }
                    title={ _t( 'resend_popup_title' ) }>
      <div className="popup__text-container">
        <p className="popup__text">{ _t( 'resend_popup_text_line_1' ) }</p>
        <p className="popup__text">{ _t( 'resend_popup_text_line_2' ) }</p>
      </div>
      <div className="popup__actions">
        <button className="popup__btn popup__btn--type-1"
                type="button"
                onClick={ () => handleClose() }>{ _t( 'resend_popup_cancel' ) }</button>
        <button className="popup__btn popup__btn--type-2"
                type="button"
                onClick={ () => handleProceed() }>{ _t( 'resend_popup_confirm' ) }</button>
      </div>
    </PopupContainer>
  )
});

export default PopupResend;