import React, { useEffect }         from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import constants
import { IDLE_STATUS } from '../../constants/loadingStatuses';
import { CONTACTS_SUBSCRIPTION_LOCATION } from '../../constants/componentLocation';

//import utils
import { _t } from '../../utils/i18n';

// import actions
import { fetchContactsSubscription } from './api/contactsSubscriptionSlice';

// import components
import ContactsContainerWrapper from '../../components/common/ContactsContainerWrapper/ContactsContainerWrapper';

export default function ContactsSubscription() {
  // define dispatch
  const dispatch = useDispatch();

  // get the global state
  const { status, metaTitle } = useSelector( state => {
    const { contactsSubscription: { status, metaTitle } } = state;

    return { status, metaTitle };
  });

  // fetch the landing data from API
  useEffect( () => { if ( status === IDLE_STATUS ) dispatch( fetchContactsSubscription() ) }, [status, dispatch] );

  // define the fields to print, export to csv and copy
  const exportFields = ['id'];

  return <ContactsContainerWrapper metaTitle={ metaTitle }
                                   location={ CONTACTS_SUBSCRIPTION_LOCATION }
                                   statsTitle={ _t( 'contacts_stats_subscription_title' ) }
                                   pageTitle
                                   pageTitleText={ _t( 'menu_subscription' ) }
                                   table
                                   tableTitle={ _t( 'contacts_subscription_table_title' ) }
                                   tableColumns={ 8 }
                                   tableSort="id"
                                   tableExportFields={ exportFields } />;
}