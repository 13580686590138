import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// import icons
import { IconCheck, IconMinus, IconCircleCheck, IconCircleMinus } from '@tabler/icons-react';

// import constants
import { CONTACTS_SUBSCRIPTION_FETCH } from '../../../constants/dispatchTypes';
import { IDLE_STATUS, LOADING_STATUS, SUCCEEDED_STATUS, FAILED_STATUS } from '../../../constants/loadingStatuses';

// import helpers
import { contactsRequest }            from '../../../api/helper';
import { replaceTableKeywordsToText } from '../../../helpers/replaceTableKeywordsToText';

// import utils
import { _t } from '../../../utils/i18n';

// define API urls
const defaultUrl = 'customer-api/get-newsletter-subscriptions';

// define table
const tableOptions = [
  {
    id: 'id',
    name: _t( 'table_order_num' ),
    selector: row => row.id,
    sortable: true,
    width: '65px',
  },
  {
    id: 'gender',
    name: _t( 'table_gender' ),
    selector: row => row.salutation,
    width: '85px',
  },
  {
    id: 'first-name',
    name: _t( 'table_first_name' ),
    selector: row => row.first_name,
    sortable: true,
  },
  {
    id: 'last-name',
    name: _t( 'table_last_name' ),
    selector: row => row.last_name,
  },
  {
    id: 'opt-in',
    name: _t( 'table_opt_in' ),
    selector: row => row.opt_in ? <IconCheck color="#444441" size={ 20 } /> : <IconMinus color="#444441" size={ 20 } />,
  },
  {
    id: 'opt-in-date',
    name: _t( 'table_opt_in_date' ),
    selector: row => row.opt_in_date,
  },
  {
    id: 'email',
    name: _t( 'table_email' ),
    selector: row => row.email,
  },
  {
    id: 'crm-sync',
    name: _t( 'table_crm_sync' ),
    selector: row => row.crm_synced ? <IconCircleCheck color="#24CE54" size={ 20 } /> : <IconCircleMinus color="#EF353F" size={ 20 } />,
  },
];

/**
 * Fetching initial data request
 *
 * @return object
 */
export const fetchContactsSubscription = createAsyncThunk(
  CONTACTS_SUBSCRIPTION_FETCH,
  async ( args, thunkAPI ) =>
    contactsRequest( args, thunkAPI, { defaultUrl } )
);

const contactsSubscriptionSlice = createSlice({
  name: 'contactsSubscription',
  initialState: {
    status: IDLE_STATUS,
    tableStatus: IDLE_STATUS,
    metaTitle: 'Newsletter Anmeldungen Contacts | Soulclick Manager',
    tableData: [],
    tableOptions,
    error: _t( 'fetch_error' )
  },
  reducers: {},
  extraReducers( builder ) {
    builder
      .addCase( fetchContactsSubscription.pending, state => {
        state.status = LOADING_STATUS;
      })
      .addCase( fetchContactsSubscription.fulfilled, ( state, action ) => {
        const { payload: tableData } = action;

        state.tableData   = tableData !== undefined ? replaceTableKeywordsToText( tableData ) : state.tableData;
        state.status      = SUCCEEDED_STATUS;
        state.tableStatus = SUCCEEDED_STATUS;
      })
      .addCase( fetchContactsSubscription.rejected, ( state, action ) => {
        state.status      = FAILED_STATUS;
        state.tableStatus = FAILED_STATUS;
        state.error       = action.error.message;

        console.log( action.error.message );
      })
  },
});

export default contactsSubscriptionSlice.reducer;