import { createSlice } from '@reduxjs/toolkit'

export const popupResendSlice = createSlice({
  name: 'resendPopup',
  initialState: {
    isShown: false,
    orderNumber: null,
    paymentMethod: 'invoice'
  },
  reducers: {
    showPopup: ( state, action ) => {
      const { isShown, orderNumber, paymentMethod } = action.payload;

      state.isShown       = isShown;
      state.orderNumber   = orderNumber;
      state.paymentMethod = paymentMethod;
    },
    hidePopup: ( state, action ) => {
      state.isShown       = action.payload;
      state.orderNumber   = null;
      state.paymentMethod = 'invoice';
    },
  }
});

export default popupResendSlice.reducer;