import React, { memo } from 'react';

const ProductsPerformingTitle = memo( ( { title, extraClass } ) => {
  return (
    <div className={ `products-performing__column ${ extraClass }` }>
      <h4 className="products-performing__title">{ title }</h4>
    </div>
  )
});

export default ProductsPerformingTitle;