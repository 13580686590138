import { createSlice } from '@reduxjs/toolkit'

export const popupContactsSlice = createSlice({
  name: 'contactsPopup',
  initialState: {
    isShown    : false,
    contactType: '',
    firstName  : '',
    lastName   : '',
    streetNr   : '',
    salutation : '',
    id         : '',
    zip        : '',
    city       : '',
    street     : '',
    email      : '',
    company    : ''
  },
  reducers: {
    triggerPopup: ( state, action ) => {
      const {
        isShown, contactType, firstName, lastName, zip, city, street, streetNr, email, id, salutation, company
      } = action.payload;

      state.isShown     = isShown;
      state.contactType = contactType;
      state.firstName   = firstName;
      state.lastName    = lastName;
      state.zip         = zip;
      state.city        = city;
      state.street      = street;
      state.streetNr    = streetNr;
      state.email       = email;
      state.id          = id;
      state.salutation  = salutation;
      state.company     = company;
    },
  }
});

export default popupContactsSlice.reducer;