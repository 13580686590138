import React                                   from 'react';
import ReactDOM                                from 'react-dom/client';
import { Provider }                            from 'react-redux';
import store                                   from './store';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// import constants
import {
  ROOT_PATH, ALL_PAYMENTS_PATH, LOGIN_PATH, INVOICE_METHOD_PATH, ALL_DONATIONS_PATH, RECURRING_DONATIONS_PATH,
  RECURRING_DONATIONS_INTERVAL_PATH, SINGLE_DONATIONS_PATH, CROWDFUNDING_PATH, ALL_PAYMENT_METHODS_PATH,
  CREDIT_CARD_METHOD_PATH, EWALLET_METHOD_PATH, TWINT_METHOD_PATH, POSTFINANCE_METHOD_PATH, PAYPAL_METHOD_PATH,
  CONTACTS_B2B_PATH, CONTACTS_B2C_PATH, CONTACTS_NL_SUBSCRIPTION_PATH,
  LSV_PATH, LSV_DEBIT_PATH, LSV_HISTORY_PATH
} from './constants/paths';

// import utils
import { isUserLoggedIn, isUserRemembered, getUserPackage } from './utils/Auth';

// import components
import ProtectedRoute             from './components/common/ProtectedRoute';
import App                        from './components/App';
import AllPayments                from './pages/AllPayments';
import AllDonations               from './pages/AllDonations';
import RecurringDonations         from './pages/RecurringDonations';
import RecurringDonationsInterval from './pages/RecurringDonationsInterval';
import SingleDonations            from './pages/SingleDonations';
import Crowdfunding               from './pages/Crowdfunding';
import AllMethods                 from './pages/AllMethods';
import CreditCardPayments         from './pages/CreditCardPayments';
import EwalletPayments            from './pages/EwalletPayments';
import TwintPayments              from './pages/TwintPayments';
import PostfinancePayments        from './pages/PostfinancePayments';
import PaypalPayments             from './pages/PaypalPayments';
import InvoicePayments            from './pages/InvoicePayments';
import ContactsB2C                from './pages/ContactsB2C';
import ContactsB2B                from './pages/ContactsB2B';
import ContactsSubscription       from './pages/ContactsSubscription';
import LSV                        from './pages/LSV';
import LSVDebit                   from './pages/LSVDebit';
import LSVHistory                 from './pages/LSVHistory';
import Login                      from './pages/Login';
import Error                      from './pages/Error';

const router = createBrowserRouter([
  {
    path: ROOT_PATH,
    element: <ProtectedRoute redirectPath={ LOGIN_PATH } isAllowed={ isUserLoggedIn() }><App /></ProtectedRoute>,
    errorElement: <Error />,
    children: [
      {
        path: ALL_PAYMENTS_PATH,
        element: <AllPayments />,
      },
      {
        path: ALL_DONATIONS_PATH,
        element: <AllDonations />,
      },
      {
        path: RECURRING_DONATIONS_PATH,
        element: <RecurringDonations />,
      },
      {
        path: RECURRING_DONATIONS_INTERVAL_PATH,
        element: <RecurringDonationsInterval />,
      },
      {
        path: SINGLE_DONATIONS_PATH,
        element: <SingleDonations />,
      },
      {
        path: CROWDFUNDING_PATH,
        element: <ProtectedRoute redirectPath={ ROOT_PATH } isAllowed={ getUserPackage( isUserRemembered() ) === 'crowd' }><Crowdfunding /></ProtectedRoute>,
      },
      {
        path: ALL_PAYMENT_METHODS_PATH,
        element: <AllMethods />,
      },
      {
        path: CREDIT_CARD_METHOD_PATH,
        element: <CreditCardPayments />,
      },
      {
        path: EWALLET_METHOD_PATH,
        element: <EwalletPayments />,
      },
      {
        path: TWINT_METHOD_PATH,
        element: <TwintPayments />,
      },
      {
        path: POSTFINANCE_METHOD_PATH,
        element: <PostfinancePayments />,
      },
      {
        path: PAYPAL_METHOD_PATH,
        element: <PaypalPayments />,
      },
      {
        path: INVOICE_METHOD_PATH,
        element: <InvoicePayments />,
      },
      {
        path: CONTACTS_B2C_PATH,
        element: <ContactsB2C />,
      },
      {
        path: CONTACTS_B2B_PATH,
        element: <ContactsB2B />,
      },
      {
        path: CONTACTS_NL_SUBSCRIPTION_PATH,
        element: <ContactsSubscription />,
      },
      /*{
        path: LSV_PATH,
        element: <LSV />,
      },
      {
        path: LSV_DEBIT_PATH,
        element: <LSVDebit />,
      },
      {
        path: LSV_HISTORY_PATH,
        element: <LSVHistory />,
      },*/
    ],
  },
  {
    path: LOGIN_PATH,
    element: <ProtectedRoute redirectPath={ ROOT_PATH } isAllowed={ !isUserLoggedIn() }><Login /></ProtectedRoute>,
  }
]);

ReactDOM.createRoot( document.getElementById( 'root' ) ).render(
  <Provider store={ store }>
    <RouterProvider router={ router } />
  </Provider>
);
