// import constants
import { BASE_CURRENCY } from '../constants/base';

/**
 * Convert numbers to Swiss format (1000 -> 1'000)
 *
 * @param number | int, float, string
 * @return string
 */
export const swissNumberFormat = number =>
  new Intl.NumberFormat( 'de-CH' ).format( number );

/**
 * Helper to return swiss number format in any representations
 *
 * @param value | string
 * @param currency | bool
 * @return string
 */
export const swissNumberInConfig = ( value, currency = false ) => {
  const isFloat = value % 1 !== 0;

  if ( currency ) {
    return isFloat ? BASE_CURRENCY + ' ' + swissNumberFormat( value.toFixed( 2 ) ) : BASE_CURRENCY + ' ' + swissNumberFormat( value );
  } else {
    return isFloat ? swissNumberFormat( value.toFixed( 2 ) ) : swissNumberFormat( value );
  }
};