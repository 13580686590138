import React, { memo } from 'react';

// import constants
import { INVOICE_PAYMENT_METHOD } from '../../../constants/base';

// import components
import TableActionInactive from './TableActionInactive';
import TableActionResend   from './TableActionResend';

const TableActions = memo( ({ row }) => {
  const { active, payment_method } = row;

  return (
    <div className="table__actions-cell">
      { active ? <TableActionInactive data={ row } /> : false }
      { payment_method === INVOICE_PAYMENT_METHOD ? <TableActionResend data={ row } /> : false }
    </div>
  );
});

export default TableActions;