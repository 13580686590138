import React             from 'react';
import { useRouteError } from 'react-router-dom';

// import components
import Sidebar  from '../../components/common/Sidebar/Sidebar';
import Error404 from '../../components/common/Error/Error404';

export default function Error() {
  const { status } = useRouteError();

  return (
    <main className="app-container">
      <Sidebar />
      { status === 404 ? <Error404 /> : false }
    </main>
  );
}