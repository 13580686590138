import React, { useEffect } from 'react';
import { useSelector }      from 'react-redux';

// import components
import SidebarTools from '../../elements/SidebarTools/SidebarTools';
import SidebarNav   from '../../elements/SidebarNav/SidebarNav';

export default function Sidebar() {
  const { theme, isCollapsed } = useSelector( state => state.sidebar );

  useEffect(() => {
    document.documentElement.dataset.theme = theme;
    //localStorage.setItem( 'theme', theme );
  }, [theme] );

  return (
    <aside className={ isCollapsed ? 'sidebar sidebar--is-collapsed' : 'sidebar' }>
      <SidebarTools />
      <SidebarNav />
    </aside>
  )
}