import { useLocation } from 'react-router-dom';

/**
 * A helper function to get current and previous path
 *
 * @return object
 */
export default () => {
  const location = useLocation();
  const path     = location.pathname;
  const store    = window.localStorage;

  let currentUrl = '';
  let prevUrl    = '';

  currentUrl = store.getItem( 'currentUrl' );
  store.setItem( 'prevUrl', currentUrl );
  store.setItem( 'currentUrl', path );

  currentUrl = store.getItem( 'currentUrl' );
  prevUrl    = store.getItem( 'prevUrl' );

  return { currentUrl, prevUrl };
}