export const LOGIN_LOGIN                               = 'login/logIn';
export const LOGIN_LOGOUT                              = 'login/logOut';
export const LOGIN_FAILED                              = 'login/loginFailed';
export const LOGIN_CHANGE_SCREEN                       = 'login/changeScreen';
export const LOGIN_UPDATE_TOKENS                       = 'login/updateTokens';

export const TOAST_SHOW                                = 'toast/showToast';

export const LANDING_FETCH                             = 'landing/fetchLanding';
export const LANDING_DATE_UPDATE                       = 'landing/dateUpdated';
export const LANDING_STATUS_UPDATED                    = 'landing/statusUpdated';

export const ALLPAYMENTS_FETCH                         = 'allPayments/fetchAllPayments';
export const ALLPAYMENTS_DATE_UPDATE                   = 'allPayments/dateUpdated';
export const ALLPAYMENTS_GET_ALLTIME                   = 'allPayments/getAllTime';
export const ALLPAYMENTS_STATUS_UPDATED                = 'allPayments/statusUpdated';

export const INVOICEPAYMENTS_FETCH                     = 'invoicePayments/fetchInvoicePayments';
export const INVOICEPAYMENTS_DATE_UPDATE               = 'invoicePayments/dateUpdated';
export const INVOICEPAYMENTS_STATUS_UPDATED            = 'invoicePayments/statusUpdated';

export const CCPAYMENTS_FETCH                          = 'creditCardPayments/fetchCreditCardPayments';
export const CCPAYMENTS_DATE_UPDATE                    = 'creditCardPayments/dateUpdated';
export const CCPAYMENTS_STATUS_UPDATED                 = 'creditCardPayments/statusUpdated';

export const CRYPTOPAYMENTS_FETCH                      = 'cryptoPayments/fetchCryptoPayments';
export const CRYPTOPAYMENTS_DATE_UPDATE                = 'cryptoPayments/dateUpdated';
export const CRYPTOPAYMENTS_STATUS_UPDATED             = 'cryptoPayments/statusUpdated';

export const ALLMETHODSPAYMENTS_FETCH                  = 'allMethods/fetchAllMethods';
export const ALLMETHODSPAYMENTS_DATE_UPDATE            = 'allMethods/dateUpdated';
export const ALLMETHODSPAYMENTS_STATUS_UPDATED         = 'allMethods/statusUpdated';

export const POSTFINPAYMENTS_FETCH                     = 'postfinancePayments/fetchPostfinancePayments';
export const POSTFINPAYMENTS_DATE_UPDATE               = 'postfinancePayments/dateUpdated';
export const POSTFINPAYMENTS_STATUS_UPDATED            = 'postfinancePayments/statusUpdated';

export const TWINTPAYMENTS_FETCH                       = 'twintPayments/fetchTwintPayments';
export const TWINTPAYMENTS_DATE_UPDATE                 = 'twintPayments/dateUpdated';
export const TWINTPAYMENTS_STATUS_UPDATED              = 'twintPayments/statusUpdated';

export const CROWDPAYMENTS_FETCH                       = 'crowdfunding/fetchCrowdfunding';
export const CROWDPAYMENTS_DATE_UPDATE                 = 'crowdfunding/dateUpdated';
export const CROWDPAYMENTS_STATUS_UPDATED              = 'crowdfunding/statusUpdated';

export const EWALLETPAYMENTS_FETCH                     = 'ewalletPayments/fetchEwalletPayments';
export const EWALLETPAYMENTS_DATE_UPDATE               = 'ewalletPayments/dateUpdated';
export const EWALLETPAYMENTS_STATUS_UPDATED            = 'ewalletPayments/statusUpdated';

export const SHOPPAYMENTS_FETCH                        = 'shop/fetchShop';
export const SHOPPAYMENTS_DATE_UPDATE                  = 'shop/dateUpdated';
export const SHOPPAYMENTS_STATUS_UPDATED               = 'shop/statusUpdated';

export const MERCHPAYMENTS_FETCH                       = 'merchandise/fetchMerchandise';
export const MERCHPAYMENTS_DATE_UPDATE                 = 'merchandise/dateUpdated';
export const MERCHPAYMENTS_STATUS_UPDATED              = 'merchandise/statusUpdated';

export const SINGLEPAYMENTS_FETCH                      = 'singleDonations/fetchSingleDonations';
export const SINGLEPAYMENTS_DATE_UPDATE                = 'singleDonations/dateUpdated';
export const SINGLEPAYMENTS_STATUS_UPDATED             = 'singleDonations/statusUpdated';

export const RECURRINGPAYMENTS_FETCH                   = 'recurringDonations/fetchRecurringDonations';
export const RECURRINGPAYMENTS_DATE_UPDATE             = 'recurringDonations/dateUpdated';
export const RECURRINGPAYMENTS_STATUS_UPDATED          = 'recurringDonations/statusUpdated';
export const RECURRINGPAYMENTS_INACTIVE                = 'recurringDonations/makePaymentInactive';

export const RECURRINGPAYMENTS_INTERVAL_FETCH          = 'recurringDonationsInterval/fetchRecurringDonationsInterval';
export const RECURRINGPAYMENTS_INTERVAL_DATE_UPDATE    = 'recurringDonationsInterval/dateUpdated';
export const RECURRINGPAYMENTS_INTERVAL_STATUS_UPDATED = 'recurringDonationsInterval/statusUpdated';

export const ALLDONATIONSPAYMENTS_FETCH                = 'allDonations/fetchAllDonations';
export const ALLDONATIONSPAYMENTS_DATE_UPDATE          = 'allDonations/dateUpdated';
export const ALLDONATIONSPAYMENTS_STATUS_UPDATED       = 'allDonations/statusUpdated';

export const PAYPALPAYMENTS_FETCH                      = 'paypalPayments/fetchPaypalPayments';
export const PAYPALPAYMENTS_DATE_UPDATE                = 'paypalPayments/dateUpdated';
export const PAYPALPAYMENTS_STATUS_UPDATED             = 'paypalPayments/statusUpdated';

export const CONFIRMATION_POPUP_SHOW                   = 'confirmationPopup/showPopup';
export const CONFIRMATION_POPUP_HIDE                   = 'confirmationPopup/hidePopup';

export const RESEND_POPUP_SHOW                         = 'resendPopup/showPopup';
export const RESEND_POPUP_HIDE                         = 'resendPopup/hidePopup';

export const TABLE_CHANGE_ROWS_PER_PAGE                = 'table/changeRowsPerPage';

export const CONTACTS_B2C_FETCH                        = 'contacts/fetchContactsB2C';
export const CONTACTS_B2B_FETCH                        = 'contacts/fetchContactsB2B';
export const CONTACTS_SUBSCRIPTION_FETCH               = 'contacts/fetchContactsSubscription';
export const CONTACTS_POPUP_TRIGGER                    = 'contactsPopup/triggerPopup';
export const CONTACTS_UPDATED_B2C                      = 'contactsB2C/updateTable';
export const CONTACTS_UPDATED_B2B                      = 'contactsB2B/updateTable';
