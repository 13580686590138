import React, { memo } from 'react';

// import components
import SkeletonLine from '../../common/Skeleton/SkeletonLine';

const TotalAmountItemSkeleton = memo( () => {
  return (
    <div className="total-amount-item__skeleton-item">
      <SkeletonLine className="total-amount-item__skeleton-item__bar" />
      <div className="total-amount-item__skeleton-item__sides">
        <SkeletonLine className="total-amount-item__skeleton-item__left" />
        <SkeletonLine className="total-amount-item__skeleton-item__right" />
      </div>
    </div>
  )
});

export default TotalAmountItemSkeleton;