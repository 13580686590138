import React, { memo } from 'react';

// import components
import SkeletonCircle from '../../common/Skeleton/SkeletonCircle';
import SkeletonLine   from '../../common/Skeleton/SkeletonLine';

const LatestDonationItemSkeleton = memo( () => {
  return (
    <div className="latest-donation-item__skeleton-item">
      <SkeletonCircle className="latest-donation-item__skeleton-item__circle" />
      <div className="latest-donation-item__skeleton-item__right">
        <SkeletonLine className="latest-donation-item__skeleton-item__line latest-donation-item__skeleton-item__line--top" />
        <SkeletonLine className="latest-donation-item__skeleton-item__line latest-donation-item__skeleton-item__line--middle" />
        <SkeletonLine className="latest-donation-item__skeleton-item__line latest-donation-item__skeleton-item__line--bottom" />
      </div>
    </div>
  )
});

export default LatestDonationItemSkeleton;