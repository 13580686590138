import React, { memo } from 'react';

// import helpers
import { getPaymentMethodName } from '../../../helpers/getPaymentMethod';

const TablePaymentMethod = memo( ( { data } ) => {
  const { payment_parameter } = data;
  const { name, image }       = getPaymentMethodName( payment_parameter );

  return (
    <div className="table__payment-method-cell">
      <img className="table__payment-method-cell__img"
           src={ image }
           alt={ name } />
      <span className="table__payment-method-cell__text">{ name }</span>
    </div>
  )
});

export default TablePaymentMethod;