import React, { memo, useState }    from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment                       from 'moment';
import DateRangePicker              from 'react-bootstrap-daterangepicker';

// import styles
import 'bootstrap-daterangepicker/daterangepicker.css';

// import localisations
import 'moment/locale/de-ch';
import 'moment/locale/en-in';

// import constants
import { IDLE_STATUS, LOADING_STATUS } from '../../../constants/loadingStatuses';
import {
  ALL_PAYMENTS_LOCATION, ALL_DONATIONS_LOCATION, RECURRING_DONATIONS_LOCATION, SINGLE_DONATIONS_LOCATION,
  CROWDFUNDING_LOCATION, MERCHANDISE_LOCATION, SHOP_LOCATION, ALL_PAYMENT_METHODS_LOCATION,
  CREDIT_CARD_PAYMENTS_LOCATION, EWALLET_PAYMENTS_LOCATION, TWINT_PAYMENTS_LOCATION, POSTFINANCE_PAYMENTS_LOCATION,
  CRYPTO_PAYMENTS_LOCATION, INVOICE_PAYMENTS_LOCATION, PAYPAL_PAYMENTS_LOCATION, RECURRING_DONATIONS_INTERVAL_LOCATION
} from '../../../constants/componentLocation';
import {
  TODAY_LABEL, YESTERDAY_LABEL, LAST_7_DAYS_LABEL, LAST_30_DAYS_LABEL, THIS_MONTH_LABEL, LAST_MONTH_LABEL,
  THIS_QUARTER_LABEL, LAST_QUARTER_LABEL, THIS_YEAR_LABEL, LAST_YEAR_LABEL
} from '../../../constants/calendarRanges';

// import utils
import { _t, momentLocale } from '../../../utils/i18n';

// import actions
import { updatingLanding, landingStatusUpdating }                                       from '../../../pages/Statistics/api/statisticsSlice';
import { updatingAllPayments, allPaymentsStatusUpdating }                               from '../../../pages/AllPayments/api/allPaymentsSlice';
import { updatingAllDonations, allDonationsStatusUpdating }                             from '../../../pages/AllDonations/api/allDonationsSlice';
import { updatingRecurringDonations, recurringDonationsStatusUpdating }                 from '../../../pages/RecurringDonations/api/recurringDonationsSlice';
import { updatingRecurringDonationsInterval, recurringDonationsIntervalStatusUpdating } from '../../../pages/RecurringDonationsInterval/api/recurringDonationsIntervalSlice';
import { updatingSingleDonations, singleDonationsStatusUpdating }                       from '../../../pages/SingleDonations/api/singleDonationsSlice';
import { updatingCrowdfunding, crowdfundingStatusUpdating }                             from '../../../pages/Crowdfunding/api/crowdfundingSlice';
import { updatingMerchandise, merchandiseStatusUpdating }                               from '../../../pages/Merchandise/api/merchandiseSlice';
import { updatingShop, shopStatusUpdating }                                             from '../../../pages/Shop/api/shopSlice';
import { updatingAllMethods, allMethodStatusUpdating }                                  from '../../../pages/AllMethods/api/allMethodsSlice';
import { updatingCreditCardPayments, creditCardPaymentsStatusUpdating }                 from '../../../pages/CreditCardPayments/api/creditCardPaymentsSlice';
import { updatingEwalletPayments, ewalletPaymentsStatusUpdating }                       from '../../../pages/EwalletPayments/api/ewalletPaymentsSlice';
import { updatingTwintPayments, twintPaymentsStatusUpdating }                           from '../../../pages/TwintPayments/api/twintSlice';
import { updatingPostfinancePayments, postfinancePaymentsStatusUpdating }               from '../../../pages/PostfinancePayments/api/postfinancePaymentsSlice';
import { updatingCryptoPayments, cryptoPaymentsStatusUpdating }                         from '../../../pages/CryptoPayments/api/cryptoPaymentsSlice';
import { updatingPaypalPayments, paypalPaymentsStatusUpdating }                         from '../../../pages/PaypalPayments/api/paypalPaymentsSlice';
import { updatingInvoicePayments, invoicePaymentsStatusUpdating }                       from '../../../pages/InvoicePayments/api/invoicePaymentsSlice';
import { periodChanged, changeAllTimeAllPayments }                                      from '../../../components/elements/Calendar/store/calendarSlice';

// import icons
import { IconCalendarEvent, IconChevronDown } from '@tabler/icons-react';

const Calendar = memo( ( { location } ) => {
  // get the global state
  const { period, allTimeAllPayments } = useSelector( state => state.calendar );
  const { dateFrom: stateDateFrom, dateTo: stateDateTo } = period;

  // set the component state
  const [dateFrom, setDateFrom] = useState( stateDateFrom === '' ? moment().subtract( 29, 'days' ) : moment( new Date( stateDateFrom ) ) );
  const [dateTo, setDateTo]     = useState( stateDateTo === '' ? moment() : moment( new Date( stateDateTo ) ) );

  // define dispatch
  const dispatch = useDispatch();

  // define ranges
  const ranges = {
    [TODAY_LABEL]       : [moment(), moment()],
    [YESTERDAY_LABEL]   : [moment().subtract( 1, 'days' ), moment().subtract( 1, 'days' )],
    [LAST_7_DAYS_LABEL] : [moment().subtract( 6, 'days' ), moment()],
    [LAST_30_DAYS_LABEL]: [moment().subtract( 29, 'days' ), moment()],
    [THIS_MONTH_LABEL]  : [moment().startOf( 'month' ), moment().endOf( 'month' )],
    [LAST_MONTH_LABEL]  : [
      moment().subtract( 1, 'month' ).startOf( 'month' ),
      moment().subtract( 1, 'month' ).endOf( 'month' )
    ],
    [THIS_QUARTER_LABEL]: [moment().startOf( 'quarter' ), moment().endOf( 'quarter' )],
    [LAST_QUARTER_LABEL]: [
      moment().subtract( 1, 'quarter' ).startOf( 'quarter' ),
      moment().subtract( 1, 'quarter' ).endOf( 'quarter' )
    ],
    [THIS_YEAR_LABEL]   : [moment().startOf( 'year' ), moment().endOf( 'year' )],
    [LAST_YEAR_LABEL]   : [
      moment().subtract( 1, 'year' ).startOf( 'year' ),
      moment().subtract( 1, 'year' ).endOf( 'year' )
    ],
  };

  // define the initial settings
  const settings = {
    ranges,
    alwaysShowCalendars: true,
    opens: 'left',
    startDate: dateFrom,
    endDate: dateTo,
    applyButtonClasses: 'calendar__btn calendar__btn--apply',
    cancelButtonClasses: 'calendar__btn calendar__btn--cancel',
    locale: {
      format: 'YYYY-MM-DD',
      customRangeLabel: _t( 'calendar_custom_range' ),
      applyLabel: _t( 'calendar_apply' ),
      cancelLabel: _t( 'calendar_cancel' ),
      firstDay: 1,
      daysOfWeek: [
        _t( 'calendar_sunday' ),
        _t( 'calendar_monday' ),
        _t( 'calendar_tuesday' ),
        _t( 'calendar_wednesday' ),
        _t( 'calendar_thursday' ),
        _t( 'calendar_friday' ),
        _t( 'calendar_saturday' )
      ],
      monthNames: [
        _t( 'calendar_january' ),
        _t( 'calendar_february' ),
        _t( 'calendar_march' ),
        _t( 'calendar_april' ),
        _t( 'calendar_may' ),
        _t( 'calendar_june' ),
        _t( 'calendar_july' ),
        _t( 'calendar_august' ),
        _t( 'calendar_september' ),
        _t( 'calendar_october' ),
        _t( 'calendar_november' ),
        _t( 'calendar_december' )
      ],
    },
  };

  /**
   * Processing applying dates
   */
  const handleApply = ( event, picker ) => {
    const { startDate, endDate, chosenLabel } = picker;
    const dateFrom  = startDate._d.toISOString();
    const dateTo    = endDate._d.toISOString();
    const dateRange = {
      dateFrom: moment( dateFrom ).format( 'MM-DD-YYYY' ),
      dateTo: moment( dateTo ).format( 'MM-DD-YYYY' ),
      period: chosenLabel
    };

    // update the components state
    setDateFrom( dateFrom );
    setDateTo( dateTo );

    // update period
    dispatch( periodChanged( dateRange ) );

    // flush the status if date was changed
    dispatch( landingStatusUpdating( IDLE_STATUS ) );
    dispatch( allPaymentsStatusUpdating( IDLE_STATUS ) );
    dispatch( allDonationsStatusUpdating( IDLE_STATUS ) );
    dispatch( recurringDonationsStatusUpdating( IDLE_STATUS ) );
    dispatch( recurringDonationsIntervalStatusUpdating( IDLE_STATUS ) );
    dispatch( singleDonationsStatusUpdating( IDLE_STATUS ) );
    dispatch( crowdfundingStatusUpdating( IDLE_STATUS ) );
    dispatch( merchandiseStatusUpdating( IDLE_STATUS ) );
    dispatch( shopStatusUpdating( IDLE_STATUS ) );
    dispatch( allMethodStatusUpdating( IDLE_STATUS ) );
    dispatch( creditCardPaymentsStatusUpdating( IDLE_STATUS ) );
    dispatch( ewalletPaymentsStatusUpdating( IDLE_STATUS ) );
    dispatch( twintPaymentsStatusUpdating( IDLE_STATUS ) );
    dispatch( postfinancePaymentsStatusUpdating( IDLE_STATUS ) );
    dispatch( cryptoPaymentsStatusUpdating( IDLE_STATUS ) );
    dispatch( paypalPaymentsStatusUpdating( IDLE_STATUS ) );
    dispatch( invoicePaymentsStatusUpdating( IDLE_STATUS ) );

    // get an actual data depends from location
    switch( location ) {
      case ALL_PAYMENTS_LOCATION:
        dispatch( updatingAllPayments( dateRange ) );
        dispatch( allPaymentsStatusUpdating( LOADING_STATUS ) );
        break;
      case ALL_DONATIONS_LOCATION:
        dispatch( updatingAllDonations( dateRange ) );
        dispatch( allDonationsStatusUpdating( LOADING_STATUS ) );
        break;
      case RECURRING_DONATIONS_LOCATION:
        dispatch( updatingRecurringDonations( dateRange ) );
        dispatch( recurringDonationsStatusUpdating( LOADING_STATUS ) );
        break;
      case RECURRING_DONATIONS_INTERVAL_LOCATION:
        dispatch( updatingRecurringDonationsInterval( dateRange ) );
        dispatch( recurringDonationsIntervalStatusUpdating( LOADING_STATUS ) );
        break;
      case SINGLE_DONATIONS_LOCATION:
        dispatch( updatingSingleDonations( dateRange ) );
        dispatch( singleDonationsStatusUpdating( LOADING_STATUS ) );
        break;
      case CROWDFUNDING_LOCATION:
        dispatch( updatingCrowdfunding( dateRange ) );
        dispatch( crowdfundingStatusUpdating( LOADING_STATUS ) );
        break;
      case MERCHANDISE_LOCATION:
        dispatch( updatingMerchandise( dateRange ) );
        dispatch( merchandiseStatusUpdating( LOADING_STATUS ) );
        break;
      case SHOP_LOCATION:
        dispatch( updatingShop( dateRange ) );
        dispatch( shopStatusUpdating( LOADING_STATUS ) );
        break;
      case ALL_PAYMENT_METHODS_LOCATION:
        dispatch( updatingAllMethods( dateRange ) );
        dispatch( allMethodStatusUpdating( LOADING_STATUS ) );
        break;
      case CREDIT_CARD_PAYMENTS_LOCATION:
        dispatch( updatingCreditCardPayments( dateRange ) );
        dispatch( creditCardPaymentsStatusUpdating( LOADING_STATUS ) );
        break;
      case EWALLET_PAYMENTS_LOCATION:
        dispatch( updatingEwalletPayments( dateRange ) );
        dispatch( ewalletPaymentsStatusUpdating( LOADING_STATUS ) );
        break;
      case TWINT_PAYMENTS_LOCATION:
        dispatch( updatingTwintPayments( dateRange ) );
        dispatch( twintPaymentsStatusUpdating( LOADING_STATUS ) );
        break;
      case POSTFINANCE_PAYMENTS_LOCATION:
        dispatch( updatingPostfinancePayments( dateRange ) );
        dispatch( postfinancePaymentsStatusUpdating( LOADING_STATUS ) );
        break;
      case CRYPTO_PAYMENTS_LOCATION:
        dispatch( updatingCryptoPayments( dateRange ) );
        dispatch( cryptoPaymentsStatusUpdating( LOADING_STATUS ) );
        break;
      case PAYPAL_PAYMENTS_LOCATION:
        dispatch( updatingPaypalPayments( dateRange ) );
        dispatch( paypalPaymentsStatusUpdating( LOADING_STATUS ) );
        break;
      case INVOICE_PAYMENTS_LOCATION:
        dispatch( updatingInvoicePayments( dateRange ) );
        dispatch( invoicePaymentsStatusUpdating( LOADING_STATUS ) );
        break;
      default:
        dispatch( updatingLanding( dateRange ) );
        dispatch( landingStatusUpdating( LOADING_STATUS ) );
        break;
    }

    // change the table subtitle
    if ( allTimeAllPayments ) {
      dispatch( changeAllTimeAllPayments( false ) );
    }
  };

  // define icon color
  const iconColor = '#384253';

  return (
    <div className="calendar">
      <DateRangePicker initialSettings={ settings }
                       onApply={ handleApply }>
        <button className="calendar__btn"
                type="button">
          <IconCalendarEvent className="calendar__btn__icon"
                             color={ iconColor } />
          { moment( dateFrom ).locale( momentLocale() ).format( 'DD. MMMM, YYYY' ) } - { moment( dateTo ).locale( momentLocale() ).format( 'DD. MMMM, YYYY' ) }
          <IconChevronDown className="calendar__btn__arrow"
                           size={ 16 }
                           color={ iconColor } />
        </button>
      </DateRangePicker>
    </div>
  )
});

export default Calendar;