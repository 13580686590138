import { createSlice } from '@reduxjs/toolkit'

export const popupConfirmationSlice = createSlice({
  name: 'confirmationPopup',
  initialState: {
    isShown: false,
    id: null,
    number: '',
    paymentMethod: 'invoice'
  },
  reducers: {
    showPopup: ( state, action ) => {
      const { isShown, id, number, payment_method } = action.payload;

      state.isShown       = isShown;
      state.id            = id;
      state.number        = number;
      state.paymentMethod = payment_method;
    },
    hidePopup: ( state, action ) => {
      state.isShown       = action.payload;
      state.id            = null;
      state.number        = '';
      state.paymentMethod = 'invoice';
    },
  }
});

export default popupConfirmationSlice.reducer;