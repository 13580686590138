import React, { memo } from 'react';

// import components
import SkeletonCircle from '../../common/Skeleton/SkeletonCircle';

const ProductsPerformingSkeletonChart = memo( () => {
  return (
    <div className="products-performing__skeleton-chart">
      <SkeletonCircle className="products-performing__skeleton-item__circle" />
    </div>
  )
});

export default ProductsPerformingSkeletonChart;