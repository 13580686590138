import React, { memo } from 'react';

/**
 * Radio button component
 *
 * @param styleType | string. Available values: type-1, type-2, type-3
 * @param isDisabled | bool
 * @param id | string
 * @param name | string
 * @param label | string
 * @param value | string
 * @param checked | string
 * @param onChange | function
 */
const Radio = memo((
  { styleType = 'type-1',
    isDisabled = false,
    id, name, label, value, checked, onChange
  } ) => {
  return (
    <div className="radio">
      <input className="radio__input"
             id={ id }
             type="radio"
             name={ name }
             checked={ value === checked }
             value={ value }
             onChange={ onChange } />
        <label className={ `radio__label radio__label--${ styleType }` }
               htmlFor={ id }>{ label }</label>
    </div>
  )
});

export default Radio;