import React           from 'react';
import { useDispatch } from 'react-redux';

// import actions
import { showToast } from '../Toast/store/toastSlice';

// import utils
import { _t } from '../../../utils/i18n';

// import helpers
import { tableHeaderNameByKeyword, tableExportFields } from './table-helper';

/**
 * Convert array of objects to CSV
 * Code taken from https://codepen.io/Jacqueline34/pen/pyVoWr
 *
 * @param array | array
 * @return string
 */
function convertArrayOfObjectsToCSV( array ) {
  let result;

  const columnDelimiter = ';';
  const lineDelimiter   = '\n';
  const keys            = Object.keys( array[0] );

  result = '';
  result += keys.join( columnDelimiter );
  result += lineDelimiter;

  array.forEach( item => {
    let ctr = 0;

    keys.forEach( key => {
      if ( ctr > 0 ) result += columnDelimiter;

      result += item[key];
      ctr++;
    });

    result += lineDelimiter;
  });

  return result;
}

/**
 * Download CSV functionality
 * Code taken from https://codepen.io/Jacqueline34/pen/pyVoWr
 *
 * @param array | array
 * @param dispatch
 */
function handleDownloadCSV( array, dispatch ) {
  if ( array.length > 0 ) {
    const link = document.createElement( 'a' );
    let csv    = convertArrayOfObjectsToCSV( array );

    if ( csv == null ) return;

    const filename = 'export.csv';

    if ( !csv.match( /^data:text\/csv/i ) ) {
      csv = `data:text/csv;charset=utf-8,${ csv }`;
    }

    link.setAttribute( 'href', encodeURI( csv ) );
    link.setAttribute( 'download', filename );
    link.click();
  } else {
    dispatch( showToast( { isShown: true, type: 'warning', text: _t( 'table_no_data' ) } ) );
  }
}

/**
 * Define export functionality
 *
 * @param data | array
 * @param fields | array
 */
const TableExportCSV = ( { data, fields } ) => {
  const dispatch = useDispatch();

  // get data to export
  const desiredFields = tableExportFields( data, fields );

  // prepare data to download a csv file
  const updatedData = desiredFields.map( item => {
    // make columns title translatable
    const updatedKeys = Object.keys( item ).map( item => tableHeaderNameByKeyword( item ) );

    // validate column data
    const updatedValues = Object.values( item ).map( item => {
      if ( typeof item === 'object' ) {
        if ( item === null ) {
          return '';
        } else {
          let cellValue = '';

          item.forEach( element => {
            cellValue += element.key;
          });

          return cellValue;
        }
      } else {
        return item;
      }
    });

    return Object.keys( item ).reduce( ( obj, key, index ) => {
      return { ...obj, [updatedKeys[index]]: updatedValues[index] }
    }, {} );
  });

  return (
    <button className="table__action-btn"
            type="button"
            onClick={ () => handleDownloadCSV( updatedData, dispatch ) }>
      { _t( 'table_btn_export' ) }
    </button>
  )
};

export default TableExportCSV;