import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// import constants
import { IDLE_STATUS, LOADING_STATUS, SUCCEEDED_STATUS, FAILED_STATUS } from '../../../constants/loadingStatuses';
import { CCPAYMENTS_FETCH, CCPAYMENTS_DATE_UPDATE } from '../../../constants/dispatchTypes';

// import helpers
import { initialRequest, updateDateRequest } from '../../../api/helper';
import { paymentMethodChartData }            from '../../../helpers/getPaymentMethod';
import { replaceTableKeywordsToText }        from '../../../helpers/replaceTableKeywordsToText';

// import utils
import { _t, setMetaTitle } from '../../../utils/i18n';

// import config
import { paymentMethodsTableConfig } from '../../../components/elements/Table/config';

// define API urls
const defaultUrl = 'dashboard_stats_api/get-all-payment-methods/?payment-method-category=credit-cards';
const updateUrl  = 'dashboard_stats_api/get-all-payment-methods-update/?payment-method-category=credit-cards';

/**
 * Fetching initial data request
 */
export const fetchCreditCardPayments = createAsyncThunk(
  CCPAYMENTS_FETCH,
  async ( args, thunkAPI ) =>
    initialRequest( args, thunkAPI, { defaultUrl, updateUrl }, true )
);

/**
 * Make request to update the data if the date range was changed
 *
 * @param dateRange | object
 * @param updatingData | object
 * @return function
 */
const updatingData = {
  defaultUrl,
  updateUrl,
  dispatchType: CCPAYMENTS_DATE_UPDATE
};

export const updatingCreditCardPayments = dateRange => updateDateRequest( dateRange, updatingData, true );

const creditCardPaymentsSlice = createSlice({
  name: 'creditCardPayments',
  initialState: {
    status: IDLE_STATUS,
    tableStatus: IDLE_STATUS,
    metaTitle: '',
    topProductsChartData: [],
    tableData: [],
    tableOptions: paymentMethodsTableConfig(),
    error: _t( 'fetch_error' )
  },
  reducers: {
    statusUpdated: ( state, action ) => {
      const { payload } = action;

      state.status      = payload;
      state.tableStatus = payload;
    },
    dateUpdated: ( state, action ) => {
      const { topProductsChartData, tableData } = action.payload;

      state.topProductsChartData = topProductsChartData !== undefined ? paymentMethodChartData( topProductsChartData ) : state.topProductsChartData;
      state.tableData            = tableData !== undefined ? replaceTableKeywordsToText( tableData ) : state.tableData;
      state.status               = SUCCEEDED_STATUS;
      state.tableStatus          = SUCCEEDED_STATUS;
    },
  },
  extraReducers( builder ) {
    builder
      .addCase( fetchCreditCardPayments.pending, state => {
        state.status      = LOADING_STATUS;
        state.tableStatus = LOADING_STATUS;
      })
      .addCase( fetchCreditCardPayments.fulfilled, ( state, action ) => {
        const { topProductsChartData, tableData, metas } = action.payload;

        state.metaTitle            = metas !== undefined ? setMetaTitle( metas ) : state.metaTitle;
        state.topProductsChartData = topProductsChartData !== undefined ? paymentMethodChartData( topProductsChartData ) : state.topProductsChartData;
        state.tableData            = tableData !== undefined ? replaceTableKeywordsToText( tableData ) : state.tableData;
        state.status               = SUCCEEDED_STATUS;
        state.tableStatus          = SUCCEEDED_STATUS;
      })
      .addCase( fetchCreditCardPayments.rejected, ( state, action ) => {
        state.status      = FAILED_STATUS;
        state.tableStatus = FAILED_STATUS;
        state.error       = action.error.message;

        console.log( action.error.message );
      })
  },
});

export const { statusUpdated: creditCardPaymentsStatusUpdating } = creditCardPaymentsSlice.actions;

export default creditCardPaymentsSlice.reducer