import React                            from 'react';
import { useSelector/*, useDispatch*/ } from 'react-redux';

// import constants
import { SUPPORT_EXTERNAL_LINK } from '../../../constants/paths';

// import actions
//import { changeSection } from '../../common/Sidebar/store/sidebarSlice';

// import utils
import { _t } from '../../../utils/i18n';

// import icons
import { IconInfoCircle } from '@tabler/icons-react';

export default function SidebarToolsItemSupport() {
  // get the global state
  const { theme, isCollapsed } = useSelector( state => state.sidebar );

  // define dispatch
  //const dispatch = useDispatch();

  /**
   * Processing click
   */
  /*const handleClick = () => {
    const data = {
      activeSection: 'support',
      sectionTitle: ''
    };

    dispatch( changeSection( data ) );
  };*/

  return (
    <a className="sidebar-tools__item"
       href={ SUPPORT_EXTERNAL_LINK }
       rel="noopener noreferrer"
       target="_blank">
      <IconInfoCircle color={ theme === 'dark' ? '#fff' : '#444441' } />
      { isCollapsed ?
        <span className="sidebar-tools__item__tooltip">{ _t( 'menu_title_support' ) }</span>
        : false }
    </a>
  )
}