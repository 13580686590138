import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector }         from 'react-redux';

// import icons
import { IconX } from '@tabler/icons-react';

// import constants
import { CONTACTS_POPUP_TRIGGER }               from '../../../constants/dispatchTypes';
import { CONTACTS_B2B_LOCATION }                from '../../../constants/componentLocation';
import { CONTACTS_PRIVATE, CONTACTS_CORPORATE } from '../../../constants/base';

//import actions
import { updateContact } from '../../../pages/ContactsB2C/api/contactsB2CSlice';

// import utils
import { _t } from '../../../utils/i18n';

// import helpers
import { getGenderByKeyword } from '../../../helpers/getGenderByKeyword';
import {
  nameValidator, zipValidator, cityValidator, streetValidator, streetNumberValidator, emailValidator
} from '../../../helpers/validators';

// import components
import Input from '../../elements/Input/Input';
import Radio from '../../elements/Radio/Radio';

const PopupContacts = memo(( { location } ) => {
  // get the global state
  const {
    isShown, contactType, firstName, lastName, zip, city, street, streetNr, email, id, salutation, company
  } = useSelector( store => store.contactsPopup );

  const { tableData } = useSelector( state => {
    switch( location ) {
      case CONTACTS_B2B_LOCATION:
        return state.contactsB2B;
      /* falls through */
      default:
        return state.contactsB2C;
    }
  });

  // define dispatch
  const dispatch = useDispatch();

  // define the default errors state
  const defaultErrorsState = {
    firstName: false,
    lastName : false,
    zip      : false,
    city     : false,
    street   : false,
    streetNr : false,
    email    : false,
    company  : false,
  };

  // define the component state
  const [contactData, setContactData] = useState({
    contactType, firstName , lastName, zip, city, street, streetNr, email, id, salutation, company
  });
  const [fieldsErrors, setFieldsErrors] = useState( defaultErrorsState );

  // get keys
  const contactDataKeys = Object.keys( contactData );

  // define variables
  const gender = contactData.salutation;

  // destructuring fields errors
  const {
    firstName: firstNameErr,
    lastName : lastNameErr,
    zip      : zipErr,
    city     : cityErr,
    street   : streetErr,
    streetNr : streetNrErr,
    email    : emailErr,
    company  : companyErr
  } = fieldsErrors;

  /**
   * Update the contact values if the popup is opened
   */
  useEffect( () => {
    setContactData({ contactType, firstName, lastName, zip, city, street, streetNr, email, id, salutation, company })
  }, [contactType, firstName, lastName, zip, city, street, streetNr, email, id, salutation, company] );

  /**
   * Processing to close the popup.
   * Clear all the popup values from the store
   */
  const handleClose = () => {
    const payload = {
      isShown    : false,
      contactType: '',
      firstName  : '',
      lastName   : '',
      zip        : '',
      city       : '',
      street     : '',
      streetNr   : '',
      email      : '',
      id         : '',
      salutation : '',
      company    : '',
    };

    dispatch( { type: CONTACTS_POPUP_TRIGGER, payload } );

    // clear the error state
    setFieldsErrors( defaultErrorsState );
  };

  /**
   * Processing to close the popup if ESC key was pressed
   */
  useEffect(() => {
    if ( isShown ) {
      const closePopupByEsc = e => { if ( e.keyCode === 27 ) handleClose() };

      window.addEventListener( 'keydown', closePopupByEsc );

      return () => window.removeEventListener( 'keydown', closePopupByEsc );
    }
  },[isShown] );

  /**
   * Processing to change gender
   *
   * @param e | event
   */
  const handleChangeGender = e => setContactData({ ...contactData, salutation: getGenderByKeyword( e.target.value ) });

  /**
   * Disable error state if make focus on the input
   *
   * @param name | string
   */
  const handleFocus = name => setFieldsErrors({ ...fieldsErrors, [name]: false });

  /**
   * Processing to change an input value
   *
   * @param data | object
   */
  const handleChangeInput = data => {
    const { name, value } = data;

    setContactData({
      ...contactData,
      [name]: value
    });
  };

  /**
   * Processing to save edited data
   */
  const handleSave = () => {
    const { firstName, lastName, zip, city, street, streetNr, email, company } = contactData;

    if (
      !nameValidator( firstName ) ||
      !nameValidator( lastName ) ||
      !zipValidator( zip ) ||
      !cityValidator( city ) ||
      !streetValidator( street ) ||
      !streetNumberValidator( streetNr ) ||
      !emailValidator( email ) ||
      ( contactType === CONTACTS_CORPORATE && company === '' )
    ) {
      // add an error state to the first name field
      if ( !nameValidator( firstName ) ) {
        setFieldsErrors({ ...fieldsErrors, firstName: true });
      }

      // add an error state to the last name field
      if ( !nameValidator( lastName ) ) {
        setFieldsErrors({ ...fieldsErrors, lastName: true });
      }

      // add an error state to the email field
      if ( !emailValidator( email ) ) {
        setFieldsErrors({ ...fieldsErrors, email: true });
      }

      // add an error state to the city field
      if ( !cityValidator( city ) ) {
        setFieldsErrors({ ...fieldsErrors, city: true });
      }

      // add an error state to the street field
      if ( !streetValidator( street ) ) {
        setFieldsErrors({ ...fieldsErrors, street: true });
      }

      // add an error state to the street number field
      if ( !streetNumberValidator( streetNr ) ) {
        setFieldsErrors({ ...fieldsErrors, streetNr: true });
      }

      // add an error state to the zip field
      if ( !zipValidator( zip ) ) {
        setFieldsErrors({ ...fieldsErrors, zip: true });
      }

      // add an error state to the company field
      if ( contactType === CONTACTS_CORPORATE && company === '' ) {
        setFieldsErrors({ ...fieldsErrors, company: true });
      }
    } else {
      dispatch( updateContact( contactData, tableData ) );
    }
  };

  return (
    <div className={ isShown ? 'popup popup--is-opened' : 'popup' }>
      <div className="popup__overlay"
           onClick={ () => handleClose() } />
      <div className={ isShown ? 'popup__content popup__content--contacts popup__content--is-opened' : 'popup__content popup__content--contacts' }>
        <div className="popup__close"
             onClick={ () => handleClose() }>
          <IconX color="#384253" />
        </div>

        <div className="popup__form">
          <h2 className="popup__form__title">{ _t( 'contacts_form_title' ) }</h2>
          <p className="radio__container__label">{ _t( 'table_gender' ) }</p>

          <div className="radio__container radio__container--row">
            <Radio id="contacts-gender-female"
                   name="contacts-gender"
                   label={ _t( 'contacts_form_gender_female' ) }
                   value="Female"
                   checked={ gender === _t( 'contacts_form_gender_female' ) ? 'Female' : '' }
                   onChange={ handleChangeGender } />

            <Radio id="contacts-gender-male"
                   name="contacts-gender"
                   label={ _t( 'contacts_form_gender_male' ) }
                   value="Male"
                   checked={ gender === _t( 'contacts_form_gender_male' ) ? 'Male' : '' }
                   onChange={ handleChangeGender } />

            <Radio id="contacts-gender-none"
                   name="contacts-gender"
                   label={ _t( 'contacts_form_gender_none' ) }
                   value="Keine Angabe"
                   checked={ gender }
                   onChange={ handleChangeGender } />
          </div>

          <div className="input__container input__container--column">
            <Input id="contacts-type"
                   name={ contactDataKeys[0] }
                   placeholder={ _t( 'table_contact_type' ) }
                   value={ contactType === CONTACTS_PRIVATE ? _t( 'contacts_form_contact_type_b2c' ) : _t( 'contacts_form_contact_type_b2b' ) }
                   isDisabled />

            <Input id="contacts-first-name"
                   name={ contactDataKeys[1] }
                   placeholder={ _t( 'table_first_name' ) }
                   errorMsgTxt={ _t( 'contacts_form_first_name_error' ) }
                   value={ contactData.firstName }
                   onChange={ handleChangeInput }
                   onFocus={ handleFocus }
                   isError={ firstNameErr }
                   isRequired />

            <Input id="contacts-last-name"
                   name={ contactDataKeys[2] }
                   placeholder={ _t( 'table_last_name' ) }
                   errorMsgTxt={ _t( 'contacts_form_last_name_error' ) }
                   value={ contactData.lastName }
                   onChange={ handleChangeInput }
                   onFocus={ handleFocus }
                   isError={ lastNameErr }
                   isRequired />
          </div>

          <div className="input__container input__container--row">
            <Input id="contacts-zip"
                   name={ contactDataKeys[3] }
                   placeholder={ _t( 'table_zip' ) }
                   errorMsgTxt={ _t( 'contacts_form_zip_error' ) }
                   value={ contactData.zip }
                   onChange={ handleChangeInput }
                   onFocus={ handleFocus }
                   isError={ zipErr }
                   isRequired />

            <Input id="contacts-city"
                   name={ contactDataKeys[4] }
                   placeholder={ _t( 'table_city' ) }
                   errorMsgTxt={ _t( 'contacts_form_city_error' ) }
                   value={ contactData.city }
                   onChange={ handleChangeInput }
                   onFocus={ handleFocus }
                   isError={ cityErr }
                   isRequired />
          </div>

          <div className="input__container input__container--row">
            <Input id="contacts-street"
                   name={ contactDataKeys[5] }
                   placeholder={ _t( 'table_street' ) }
                   errorMsgTxt={ _t( 'contacts_form_street_error' ) }
                   value={ contactData.street }
                   onChange={ handleChangeInput }
                   onFocus={ handleFocus }
                   isError={ streetErr }
                   isRequired />

            <Input id="contacts-street-nr"
                   name={ contactDataKeys[6] }
                   placeholder={ _t( 'table_street_nr' ) }
                   errorMsgTxt={ _t( 'contacts_form_street_nr_error' ) }
                   value={ contactData.streetNr }
                   onChange={ handleChangeInput }
                   onFocus={ handleFocus }
                   isError={ streetNrErr }
                   isRequired />
          </div>

          <div className="input__container input__container--column">
            { contactType === CONTACTS_CORPORATE ?
              <Input id="contacts-company"
                     name={ contactDataKeys[10] }
                     placeholder={ _t( 'table_company' ) }
                     errorMsgTxt={ _t( 'contacts_form_company_error' ) }
                     value={ contactData.company }
                     onChange={ handleChangeInput }
                     onFocus={ handleFocus }
                     isError={ companyErr }
                     isRequired />
            : false }

            <Input id="contacts-email"
                   name={ contactDataKeys[7] }
                   placeholder={ _t( 'table_email' ) }
                   errorMsgTxt={ _t( 'contacts_form_email_error' ) }
                   value={ contactData.email }
                   onChange={ handleChangeInput }
                   onFocus={ handleFocus }
                   isError={ emailErr }
                   isRequired />
          </div>
        </div>

        <div className="popup__actions">
          <button className="popup__btn popup__btn--type-1"
                  type="button"
                  onClick={ () => handleClose() }>
            { _t( 'contacts_form_close' ) }
          </button>
          <button className="popup__btn popup__btn--type-2"
                  type="button"
                  onClick={ () => handleSave() }>
            { _t( 'contacts_form_save' ) }
          </button>
        </div>
      </div>
    </div>
  )
});

export default PopupContacts;