import React from 'react';

export default function Footer() {
  return (
    <div className="footer">
      <p className="footer__credits">© { new Date().getFullYear() } <a className="footer__credits__link"
                                               href="https://soulclick.ch"
                                               target="_blank"
                                               rel="nofollow noindex noreferrer">Soulclick</a>
      </p>
    </div>
  )
}