import React, { memo } from 'react';

// import components
import SkeletonVerticalLine from '../../common/Skeleton/SkeletonVerticalLine';
import SkeletonXaxis        from '../../common/Skeleton/SkeletonXaxis';
import SkeletonYaxis        from '../../common/Skeleton/SkeletonYaxis';

const ChartSkeleton = memo( () => {
  return (
    <div className="transaction-chart__skeleton-item">
      <div className="transaction-chart__skeleton-item__verticals">
        <SkeletonYaxis className="transaction-chart__skeleton-item__y-axis" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-1" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-2" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-3" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-1" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-2" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-3" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-1" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-2" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-3" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-1" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-2" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-3" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-1" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-2" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-3" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-1" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-2" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-3" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-1" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-2" />
        <SkeletonVerticalLine className="transaction-chart__skeleton-item__vertical-line transaction-chart__skeleton-item__vertical-line--type-3" />
      </div>
      <div className="transaction-chart__skeleton-item__horizontals">
        <SkeletonXaxis className="transaction-chart__skeleton-item__x-axis" />
      </div>
    </div>
  )
});

export default ChartSkeleton;