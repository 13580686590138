import React           from 'react';
import { useSelector } from 'react-redux';

// import constants
import { SUCCEEDED_STATUS } from '../../../constants/loadingStatuses';

// import utils
import { _t } from '../../../utils/i18n';

// import components
import CrowdfundingProjectItem         from './CrowdfundingProjectItem';
import CrowdfundingProjectItemSkeleton from './CrowdfundingProjectItemSkeleton';

export default function CrowdfundingProjects() {
  // get initial state
  const { status, projectsData } = useSelector( state => state.crowdfunding );

  // define skeleton
  const skeleton = Array.from({ length: 5 }, ( _, index ) => {
    return <CrowdfundingProjectItemSkeleton key={ index } />;
  });

  // define table heads
  const tableHeads = [
    _t( 'crowdfunding_projects_table_name' ),
    _t( 'crowdfunding_projects_table_numbers' ),
    _t( 'crowdfunding_projects_table_donated' ),
    _t( 'crowdfunding_projects_table_required' ),
    _t( 'crowdfunding_projects_table_progress' ),
    _t( 'crowdfunding_projects_table_status' ),
    _t( 'crowdfunding_projects_table_deadline' )
  ];

  return(
    <div className="crowdfunding-projects">
      <p className="crowdfunding-projects__description">{ _t( 'crowdfunding_projects_description' ) }</p>
      <div className="crowdfunding-projects__table">
        <div className="crowdfunding-projects__table__row">
          { tableHeads.map( item => <span className="crowdfunding-projects__table__head" key={ item }>{ item }</span> ) }
        </div>

        { status === SUCCEEDED_STATUS ?
          projectsData.map( item => <CrowdfundingProjectItem key={ item.id } data={ item } /> )
        : skeleton }
      </div>
    </div>
  )
}