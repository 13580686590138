import React from 'react';

// import utils
import { _t } from '../../../utils/i18n';

export default function TableNoData() {
  return (
    <div className="table__no-data">
      <p className="table__no-data__msg">{ _t( 'table_no_data' ) }</p>
    </div>
  )
}