import React           from 'react';
import { useDispatch } from 'react-redux';

// import utils
import { _t } from '../../../utils/i18n';

// import helpers
import { tableHeaderNameByKeyword, tableExportFields } from './table-helper';

// import actions
import { showToast } from '../Toast/store/toastSlice';

const TablePrint = ( { data, fields } ) => {
  const printStyles = '/print.css';
  const dispatch    = useDispatch();

  /**
   * A function to show a print modal window with the table data
   *
   * @param data | array
   * @param fields | array
   */
  const handlePrint = ( data, fields ) => {
    if ( data.length > 0 ) {
      // get data to export
      const desiredFields = tableExportFields( data, fields );

      // get headers from data
      const tableHeader = Object.keys( desiredFields[0] ).map( value => {
        return '<th class="print-table__head__cell">' + tableHeaderNameByKeyword( value ) + '</th>';
      });

      // get rows from data
      const tableRowsData = desiredFields.map( item => {
        return Object.values( item ).map( value => {
          return '<td class="print-table__body__cell">' + value + '</td>';
        });
      });

      // prepare rows
      const tableRows = tableRowsData.map( row => {
        return '<tr class="print-table__body__row">' + row.join( '' ) + '</tr>';
      });

      // create the table markup
      const table = '<table class="print-table">' +
        ' <thead class="print-table__head"><tr>' + tableHeader.join( '' ) + '</tr></thead>' +
        ' <tbody class="print-table__body">' + tableRows.join( '' ) + '</tbody>' +
        '</table>';

      const printIframe    = window.frames['print-table'];
      const printIframeDoc = printIframe.document;
      const printIframeWnd = printIframe.window;

      // write out the prepared table with the data to an iframe
      printIframeDoc.write( '<html><head><link href="' + printStyles + '" rel="stylesheet" type="text/css" media="print"></head></html>' );
      printIframeDoc.body.innerHTML = table;

      // apply styles
      setTimeout(() => {
        printIframeWnd.focus();
        printIframeWnd.print();
      }, 100 );
    } else {
      dispatch( showToast( { isShown: true, type: 'warning', text: _t( 'table_no_data' ) } ) );
    }
  };

  return (
    <button className="table__action-btn"
            type="button"
            onClick={ () => handlePrint( data, fields ) }>
      { _t( 'table_btn_print' ) }
    </button>
  )
};

export default TablePrint;