import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch }   from 'react-redux';
import { NavLink }                    from 'react-router-dom';

// import utils
import { _t } from '../../../utils/i18n';

// import actions
import { changeActiveDropdown } from '../../common/Sidebar/store/sidebarSlice';

// import components
import SidebarNavItemWithChild from './SidebarNavItemWithChild';

// import menu
import { dashboardMenu, platformMenu, automationMenu, contactsMenu } from './menu';

export default function SidebarNavTop() {
  // get the global state
  const { theme, isCollapsed, sectionTitle, activeSection } = useSelector( state => state.sidebar );

  // define the component state
  const [currentMenu, setCurrentMenu]       = useState( dashboardMenu );
  const [isSingleClicked, setSingleClicked] = useState( false );

  // define dispatch
  const dispatch = useDispatch();

  // define the component variables
  const iconColor = theme === 'dark' ? '#fff' : '#444441';

  /**
   * Get the menu type depends from the current type
   */
  useEffect( () => {
    switch ( activeSection ) {
      case 'dashboard':
        setCurrentMenu( dashboardMenu );
        break;
      case 'platform':
        setCurrentMenu( platformMenu );
        break;
      case 'automation':
        setCurrentMenu( automationMenu );
        break;
      case 'contacts':
        setCurrentMenu( contactsMenu );
        break;
      default:
        setCurrentMenu( [] );
        break;
    }
  }, [activeSection] );
  /**
   * Processing click on a single nav link
   *
   * @param link | string
   */
  const handleClickSingleNavLink = ( link ) => {
    setSingleClicked( true );
    dispatch( changeActiveDropdown( link ) );
  };

  return (
    <div className={ isCollapsed ? 'sidebar-nav__top sidebar-nav__top--is-collapsed' : 'sidebar-nav__top' }>
      { activeSection !== 'support' ?
        <>
          <h4 className="sidebar-nav__title">{ sectionTitle }</h4>

          <nav className="sidebar-nav__list">
            { currentMenu.map( item => {
              const { title, link, hasChildren, children, isDisabled } = item;

              if ( hasChildren ) {
                return <SidebarNavItemWithChild key={ link }
                                                data={{ title, children, link, iconColor, isSingleClicked, isDisabled }}
                                                flushActive={ ( value ) => setSingleClicked( value ) } />
              } else {
                if ( isDisabled ) {
                  return <span className="sidebar-nav__item sidebar-nav__item--is-disabled"
                               key={ link }
                               title={ _t( 'menu_disabled_msg' ) }>{ title }</span>
                } else {
                  return <NavLink className={ ( { isActive } ) => isActive ? 'sidebar-nav__item sidebar-nav__item--is-active' : 'sidebar-nav__item' }
                                  to={ link }
                                  key={ link }
                                  onClick={ () => handleClickSingleNavLink( link ) }>{ title }</NavLink>
                }
              }
            } ) }
          </nav>
        </>
      : false }
    </div>
  )
}