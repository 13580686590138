import React                            from 'react';
import { /*useDispatch,*/ useSelector } from 'react-redux';

// import constants
import { SUPPORT_EXTERNAL_LINK } from '../../../constants/paths';

// import actions
//import { changeSection } from '../../common/Sidebar/store/sidebarSlice';

// import utils
import { _t }      from '../../../utils/i18n';
import { getUser } from '../../../utils/Auth';

// import icons
import { IconExternalLink } from '@tabler/icons-react';

// import components
import SidebarNavLogout from './SidebarNavLogout';

export default function SidebarNavBottom() {
  const { isCollapsed, isRemembered, theme } = useSelector( state => {
    const {
      sidebar: { isCollapsed, theme },
      login: { isRemembered }
    } = state;

    return { isCollapsed, isRemembered, theme };
  });

  // define dispatch
  //const dispatch = useDispatch();

  // get username
  const { userName, company } = getUser( isRemembered );

  /**
   * Processing click on the Support and User profile link
   */
  /*const handleClick = () => {
    const data = {
      activeSection: 'support',
      sectionTitle: ''
    };

    dispatch( changeSection( data ) );
  };*/

  return (
    <div className={ isCollapsed ? 'sidebar-nav__bottom sidebar-nav__bottom--is-collapsed' : 'sidebar-nav__bottom' }>
      {/*<NavLink className={ ( { isActive } ) => isActive ? 'sidebar-nav__item sidebar-nav__item--bottom sidebar-nav__item--is-active' : 'sidebar-nav__item sidebar-nav__item--bottom' }
               to={ SUPPORT_PATH }
               onClick={ () => handleClick() }>
        { _t( 'menu_title_support' ) }
      </NavLink>*/}
      <a className="sidebar-nav__item sidebar-nav__item--bottom sidebar-nav__item--bottom--w-icon"
         href={ SUPPORT_EXTERNAL_LINK }
         rel="noopener noreferrer"
         target="_blank">
        { _t( 'menu_title_support' ) }
        <IconExternalLink className="sidebar-nav__item__external-link-icon"
                          color={ theme === 'dark' ? '#fff' : '#444441' }
                          size={ 16 } />
      </a>

      <div className="sidebar-nav__item sidebar-nav__item--bottom sidebar-nav__item--is-disabled sidebar-nav__item--is-user">
        <div className="sidebar-nav__item__user"
             title={ _t( 'menu_disabled_msg' ) }>
          <span className="sidebar-nav__item__user__name">{ userName }</span>
          <span className="sidebar-nav__item__user__company">{ company }</span>
        </div>
        <SidebarNavLogout />
      </div>
    </div>
  );
}