export const ROOT_PATH                         = '/';
export const ALL_PAYMENTS_PATH                 = '/payments/all';
export const ALL_DONATIONS_PATH                = '/payments/all-donations';
export const RECURRING_DONATIONS_PATH          = '/payments/recurring-donations';
export const RECURRING_DONATIONS_INTERVAL_PATH = '/payments/recurring-donations-interval';
export const SINGLE_DONATIONS_PATH             = '/payments/single-donations';
export const CROWDFUNDING_PATH                 = '/payments/crowdfunding';
export const MERCHANDISE_PATH                  = '/payments/merchandise';
export const SHOP_PATH                         = '/payments/shop';
export const ALL_PAYMENT_METHODS_PATH          = '/payments/all-payments';
export const CREDIT_CARD_METHOD_PATH           = '/payments/credit-card-payments';
export const EWALLET_METHOD_PATH               = '/payments/e-wallet';
export const TWINT_METHOD_PATH                 = '/payments/twint';
export const POSTFINANCE_METHOD_PATH           = '/payments/postfinance';
export const CRYPTO_METHOD_PATH                = '/payments/crypto-payments';
export const PAYPAL_METHOD_PATH                = '/payments/paypal';
export const INVOICE_METHOD_PATH               = '/payments/invoices';
export const CONTACTS_B2C_PATH                 = '/contacts/b2c';
export const CONTACTS_B2B_PATH                 = '/contacts/b2b';
export const CONTACTS_NL_SUBSCRIPTION_PATH     = '/contacts/subscriptions';
export const LOGIN_PATH                        = '/login';
export const LSV_PATH                          = '/lsv';
export const LSV_DEBIT_PATH                    = '/lsv-debit';
export const LSV_HISTORY_PATH                  = '/lsv-history';
export const SUPPORT_EXTERNAL_LINK             = 'https://marketingmonkeys.atlassian.net/servicedesk/customer/portal/16';
