import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// import constants
import { CONTACTS_B2B_FETCH } from '../../../constants/dispatchTypes';
import { IDLE_STATUS, LOADING_STATUS, SUCCEEDED_STATUS, FAILED_STATUS } from '../../../constants/loadingStatuses';

// import helpers
import { contactsRequest }            from '../../../api/helper';
import { replaceTableKeywordsToText } from '../../../helpers/replaceTableKeywordsToText';

// import utils
import { _t } from '../../../utils/i18n';

// import components
import TableEditContact from '../../../components/elements/Table/TableEditContact';

// define API urls
const defaultUrl = 'customer-api/get-customers?type=b2b';

// define table
const tableOptions = [
  {
    id: 'actions',
    name: _t( 'table_actions' ),
    selector: row => <TableEditContact row={ row } />,
    width: '65px',
  },
  {
    id: 'id',
    name: _t( 'table_order_num' ),
    selector: row => row.id,
    sortable: true,
    width: '65px',
  },
  {
    id: 'gender',
    name: _t( 'table_gender' ),
    selector: row => row.salutation,
    width: '85px',
  },
  {
    id: 'first-name',
    name: _t( 'table_first_name' ),
    selector: row => row.first_name,
    sortable: true,
  },
  {
    id: 'last-name',
    name: _t( 'table_last_name' ),
    selector: row => row.last_name,
  },
  {
    id: 'street',
    name: _t( 'table_street' ),
    selector: row => row.street,
  },
  {
    id: 'street-nr',
    name: _t( 'table_street_nr' ),
    selector: row => row.street_nr,
    width: '65px',
  },
  {
    id: 'zip',
    name: _t( 'table_zip' ),
    selector: row => row.zip,
    width: '100px',
  },
  {
    id: 'city',
    name: _t( 'table_city' ),
    selector: row => row.city,
  },
  {
    id: 'country',
    name: _t( 'table_country' ),
    selector: row => row.country,
    width: '65px',
  },
  {
    id: 'email',
    name: _t( 'table_email' ),
    selector: row => row.email,
  },
  {
    id: 'company',
    name: _t( 'table_company' ),
    selector: row => row.company_name,
  },
];

/**
 * Fetching initial data request
 *
 * @return object
 */
export const fetchContactsB2B = createAsyncThunk(
  CONTACTS_B2B_FETCH,
  async ( args, thunkAPI ) =>
    contactsRequest( args, thunkAPI, { defaultUrl } )
);

const contactsB2BSlice = createSlice({
  name: 'contactsB2B',
  initialState: {
    status: IDLE_STATUS,
    tableStatus: IDLE_STATUS,
    metaTitle: 'B2B Contacts | Soulclick Manager',
    tableData: [],
    tableOptions,
    error: _t( 'fetch_error' )
  },
  reducers: {
    updateTable: ( state, action ) => {
      const { payload } = action;

      state.status      = IDLE_STATUS;
      state.tableStatus = IDLE_STATUS;
      state.tableData   = payload !== undefined ? replaceTableKeywordsToText( payload ) : state.tableData;
    }
  },
  extraReducers( builder ) {
    builder
      .addCase( fetchContactsB2B.pending, state => {
        state.status = LOADING_STATUS;
      })
      .addCase( fetchContactsB2B.fulfilled, ( state, action ) => {
        const { payload: tableData } = action;

        state.tableData   = tableData !== undefined ? replaceTableKeywordsToText( tableData ) : state.tableData;
        state.status      = SUCCEEDED_STATUS;
        state.tableStatus = SUCCEEDED_STATUS;
      })
      .addCase( fetchContactsB2B.rejected, ( state, action ) => {
        state.status      = FAILED_STATUS;
        state.tableStatus = FAILED_STATUS;
        state.error       = action.error.message;

        console.log( action.error.message );
      })
  },
});

export default contactsB2BSlice.reducer;