export const LANDING_LOCATION                      = 'landing';
export const ALL_PAYMENTS_LOCATION                 = 'all-payments';
export const ALL_DONATIONS_LOCATION                = 'all-donations';
export const RECURRING_DONATIONS_LOCATION          = 'recurring-donations';
export const RECURRING_DONATIONS_INTERVAL_LOCATION = 'recurring-donations-interval';
export const SINGLE_DONATIONS_LOCATION             = 'single-donations';
export const CROWDFUNDING_LOCATION                 = 'crowdfunding';
export const MERCHANDISE_LOCATION                  = 'merchandise';
export const SHOP_LOCATION                         = 'shop';
export const ALL_PAYMENT_METHODS_LOCATION          = 'all-methods';
export const CREDIT_CARD_PAYMENTS_LOCATION         = 'credit-card-payments';
export const EWALLET_PAYMENTS_LOCATION             = 'e-wallet';
export const TWINT_PAYMENTS_LOCATION               = 'twint';
export const POSTFINANCE_PAYMENTS_LOCATION         = 'postfinance';
export const CRYPTO_PAYMENTS_LOCATION              = 'crypto-payments';
export const PAYPAL_PAYMENTS_LOCATION              = 'paypal';
export const INVOICE_PAYMENTS_LOCATION             = 'invoice';
export const CONTACTS_B2C_LOCATION                 = 'contacts-b2c';
export const CONTACTS_B2B_LOCATION                 = 'contacts-b2b';
export const CONTACTS_SUBSCRIPTION_LOCATION        = 'contacts-subscription';