import React, { useEffect, useState } from 'react';
import { useSelector }                from 'react-redux';
import { Helmet }                     from 'react-helmet';

// import assets
import logo from '../../assets/images/logo-v-2.svg';

// import utils
import { checkBrowserLang } from '../../utils/i18n';

// import helpers
import { getWindowDimensions } from '../../helpers/getWindowDimensions';

// import components
import LoginForm         from '../../components/common/Login/LoginForm';
import LoginForgot       from '../../components/common/Login/LoginForgot';
import Toast             from '../../components/elements/Toast/Toast';
import UnderConstruction from '../../components/common/Main/UnderConstruction';

export default function Login() {
  // get the global state
  const { isLoginScreen } = useSelector( state => state.login );

  // define the component state
  const [windowSize, setWindowSize] = useState( getWindowDimensions() );

  const browserLang = checkBrowserLang();

  // get the windows dimensions if the window was resized
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize( getWindowDimensions() );
    }

    window.addEventListener( 'resize', handleWindowResize );

    return () => {
      window.removeEventListener( 'resize', handleWindowResize );
    };
  }, [] );

  return (
    <div className="login">
      <Helmet htmlAttributes={{ lang : browserLang }} />
      { windowSize.innerWidth > 1199 ?
        <>
          <Toast />
          <div className="login__container">
            <img className="login__logo"
                 src={ logo }
                 alt="Soulclick" />
            <div className="login__area">
              { isLoginScreen ? <LoginForm /> : <LoginForgot /> }
            </div>
          </div>
        </>
      :
        <UnderConstruction location="login" />
      }
    </div>
  )
}