import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch }   from 'react-redux';
import { useLocation }                from 'react-router-dom';
import moment                         from 'moment';

// import constants
import { IDLE_STATUS }           from '../../constants/loadingStatuses';
import { ALL_PAYMENTS_LOCATION } from '../../constants/componentLocation';
import {
  PERIOD_30_DAYS, PERIOD_YESTERDAY, PERIOD_DAY_BEFORE_YESTERDAY, PERIOD_LAST_WEEK, PERIOD_LAST_MONTH, PERIOD_LAST_YEAR,
  PERIOD_MONTH_BEFORE_THIS_MONTH, PERIOD_LAST_QUARTER, PERIOD_QUARTER_BEFORE_LAST_QUARTER, PERIOD_YEAR_BEFORE_LAST_YEAR
} from '../../constants/calendarRanges';

// import actions
import { fetchAllPayments } from '../AllPayments/api/allPaymentsSlice';

// import helpers
import getLocation               from '../../helpers/getLocation';
import { hideErrorNotification } from '../../helpers/hideErrorNotification';
import { tableDescription }      from '../../components/elements/Table/table-helper';

// import utils
import { getUserPackage, isUserRemembered } from '../../utils/Auth';
import { _t, momentLocale }                 from '../../utils/i18n';

// import components
import ContainerWrapper from '../../components/common/ContainerWrapper/ContainerWrapper';

export default function AllPayments() {
  // define dispatch
  const dispatch = useDispatch();

  // define location
  const location    = useLocation();
  const { prevUrl } = getLocation();

  // get the global state
  const { status, topStatsData, period, allTimeAllPayments, isShown, metaTitle } = useSelector( state => {
    const {
      allPayments: { status, topStatsData, metaTitle },
      calendar: { period, allTimeAllPayments },
      toast: { isShown },
    } = state;

    return { status, topStatsData, period, allTimeAllPayments, isShown, metaTitle };
  });

  const { dateFrom: stateDateFrom, dateTo: stateDateTo, period: calendarPeriod } = period;

  // set the component state
  const [isPeriodCustom, setIsPeriodCustom] = useState( false );
  const [dateFrom, setDateFrom] = useState( stateDateFrom === '' ? moment().subtract( 29, 'days' ) : moment( new Date( stateDateFrom ) ) );
  const [dateTo, setDateTo]     = useState( stateDateTo === '' ? moment() : moment( new Date( stateDateTo ) ) );
  const [isAllTimeAllPayments, setIsAllTimeAllPayments] = useState( allTimeAllPayments );

  // fetch the all payments data from API
  useEffect( () => { if ( status === IDLE_STATUS ) dispatch( fetchAllPayments() ) }, [status, dispatch] );

  // check if toast is shown and hide it
  useEffect( () => hideErrorNotification( dispatch, isShown, prevUrl ), [location] );

  // update date range
  useEffect( () => {
    setDateFrom( stateDateFrom === '' ? moment().subtract( 29, 'days' ) : moment( new Date( stateDateFrom ) ) );
    setDateTo( stateDateTo === '' ? moment() : moment( new Date( stateDateTo ) ) );
    setIsAllTimeAllPayments( allTimeAllPayments );
  }, [stateDateFrom, stateDateTo, allTimeAllPayments] );

  // get table description
  const tablePeriod = topStatsData.length > 0 ? topStatsData[0].dynamicPeriod : '';

  // check if the period is custom
  useEffect( () => {
    if (
      tablePeriod !== PERIOD_30_DAYS &&
      tablePeriod !== PERIOD_YESTERDAY &&
      tablePeriod !== PERIOD_DAY_BEFORE_YESTERDAY &&
      tablePeriod !== PERIOD_LAST_WEEK &&
      tablePeriod !== PERIOD_LAST_MONTH &&
      tablePeriod !== PERIOD_MONTH_BEFORE_THIS_MONTH &&
      tablePeriod !== PERIOD_LAST_QUARTER &&
      tablePeriod !== PERIOD_QUARTER_BEFORE_LAST_QUARTER &&
      tablePeriod !== PERIOD_LAST_YEAR &&
      tablePeriod !== PERIOD_YEAR_BEFORE_LAST_YEAR
    ) {
      setIsPeriodCustom( true );
    } else {
      setIsPeriodCustom( false );
    }
  }, [tablePeriod] );

  // define a custom period
  const customPeriod = isPeriodCustom ? `${ _t( 'table_from' ) } ${ moment( dateFrom ).locale( momentLocale() ).format( 'DD. MMMM, YYYY' ) } - ${ moment( dateTo ).locale( momentLocale() ).format( 'DD. MMMM, YYYY' ) }` : tableDescription( calendarPeriod );

  // define the fields to print, export to csv and copy
  const exportFields = ['order_nr', 'date', 'full_address', 'currency', 'amount', 'donation_type', 'product', 'donation_purpose', 'payment_method'];

  return <ContainerWrapper metaTitle={ metaTitle }
                           location={ ALL_PAYMENTS_LOCATION }
                           popupResend
                           topStats
                           pageTitle
                           pageTitleText={ _t( 'all_payments_title' ) }
                           pageDescriptionText={ _t( 'all_payments_description' ) }
                           topProducts
                           topProductsTitle={ getUserPackage( isUserRemembered() ) === 'smart' ? _t( 'all_payments_top_products_title_smart' ) : _t( 'all_payments_top_products_title' ) }
                           topProductsDescription="currency"
                           table
                           tableTitle={ _t( 'all_payments_table_title' ) }
                           tableDescription={ isAllTimeAllPayments ? _t( 'all_payments_table_all_times' ) : customPeriod }
                           tableColumns={ 9 }
                           tableExportFields={ exportFields }
                           tableHasButton
                           tableButtonText={ _t( 'all_payments_table_all_time_btn' ) }
                           tableModuleDescription={ _t( 'all_payments_table_description' ) } />
}