import React, { memo } from 'react';

// import components
import SkeletonLine from '../../common/Skeleton/SkeletonLine';

const ProductsPerformingSkeletonList = memo( () => {
  return (
    <div className="products-performing__skeleton-list">
      <SkeletonLine className="products-performing__skeleton-item__line products-performing__skeleton-item__line--square" />
      <SkeletonLine className="products-performing__skeleton-item__line products-performing__skeleton-item__line--long" />
      <SkeletonLine className="products-performing__skeleton-item__line products-performing__skeleton-item__line--short" />
    </div>
  )
});

export default ProductsPerformingSkeletonList;