import React, { useEffect }         from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation }              from 'react-router-dom';
import moment                       from 'moment';

// import constants
import { IDLE_STATUS }           from '../../constants/loadingStatuses';
import { CROWDFUNDING_LOCATION } from '../../constants/componentLocation';

// import actions
import { fetchCrowdfunding } from '../Crowdfunding/api/crowdfundingSlice';

// import helpers
import getLocation               from '../../helpers/getLocation';
import { hideErrorNotification } from '../../helpers/hideErrorNotification';
import { tableDescription }      from '../../components/elements/Table/table-helper';

// import utils
import { _t, momentLocale } from '../../utils/i18n';

// import components
import ContainerWrapper     from '../../components/common/ContainerWrapper/ContainerWrapper';
import ModuleWrapper        from '../../components/common/ModuleWrapper/ModuleWrapper';
import Calendar             from '../../components/elements/Calendar/Calendar';
import CrowdfundingProjects from '../../components/elements/CrowdfundingProjects/CrowdfundingProjects';

export default function Crowdfunding() {
  // define dispatch
  const dispatch = useDispatch();

  // define location
  const location    = useLocation();
  const { prevUrl } = getLocation();

  // get state
  const { status, isShown, metaTitle, period } = useSelector( state => {
    const {
      crowdfunding: { status, metaTitle },
      toast: { isShown },
      calendar: { period },
    } = state;

    return { status, isShown, metaTitle, period };
  });

  // destr period
  const { dateFrom, dateTo, period: calendarPeriod } = period;

  // fetch the crowdfunding data from API
  useEffect( () => { if ( status === IDLE_STATUS ) dispatch( fetchCrowdfunding() ) }, [status, dispatch] );

  // check if toast is shown and hide it
  useEffect( () => hideErrorNotification( dispatch, isShown, prevUrl ), [location] );

  // define a table period
  const tablePeriod = calendarPeriod === 'custom' ? `${ _t( 'table_from' ) } ${ moment( dateFrom ).locale( momentLocale() ).format( 'DD. MMMM, YYYY' ) } - ${ moment( dateTo ).locale( momentLocale() ).format( 'DD. MMMM, YYYY' ) }` : tableDescription( calendarPeriod );

  // define the fields to print, export to csv and copy
  const exportFields = ['order_nr', 'date', 'full_address', 'currency', 'amount', 'donation_type', 'product', 'donation_purpose', 'payment_method'];

  return <ContainerWrapper metaTitle={ metaTitle }
                           location={ CROWDFUNDING_LOCATION }
                           popupResend
                           topStats
                           pageTitle
                           pageTitleText={ _t( 'crowdfunding_title' ) }
                           pageDescriptionText={ _t( 'crowdfunding_description' ) }
                           table
                           tableTitle={ _t( 'crowdfunding_table_title' ) }
                           tableDescription={ tablePeriod }
                           tableColumns={ 9 }
                           tableExportFields={ exportFields }
                           tableModuleDescription={ _t( 'crowdfunding_table_description' ) }
                           childrenPosition="middle">
    <div className="row">
      <div className="col col-xs-12">
        <ModuleWrapper title={ _t( 'crowdfunding_projects_title' ) }>
          <Calendar location={ CROWDFUNDING_LOCATION } />
          <CrowdfundingProjects />
        </ModuleWrapper>
      </div>
    </div>
  </ContainerWrapper>;
}