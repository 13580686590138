import React, { memo } from 'react';

// import icons
import { IconBrandMessenger, IconX } from '@tabler/icons-react';

const PopupContainer = memo( ( { children, isShown, handleClose, title } ) => {
  return (
    <div className={ isShown ? 'popup popup--is-opened' : 'popup' }>
      <div className="popup__overlay"
           onClick={ () => handleClose() } />
      <div className={ isShown ? 'popup__content popup__content--is-opened' : 'popup__content' }>
        <div className="popup__icon">
          <IconBrandMessenger color="#63059B" />
        </div>

        <div className="popup__close"
             onClick={ () => handleClose() }>
          <IconX color="#384253" />
        </div>

        <h3 className="popup__title">{ title }</h3>
        { children }
      </div>
    </div>
  );
});

export default PopupContainer;