import React, { memo } from 'react';
import { useSelector } from 'react-redux';

// import constants
import { LOADING_STATUS } from '../../../constants/loadingStatuses';

// import components
import LatestDonationItem         from './LatestDonationItem';
import LatestDonationItemSkeleton from './LatestDonationItemSkeleton';

const LatestDonations = memo( () => {
  // get state
  const { status, latestDonationsData } = useSelector( state => state.landing );

  // define skeleton
  const skeleton = Array.from({ length: 5 }, ( _, index ) => {
    return <LatestDonationItemSkeleton key={ index } />;
  });

  return (
    <div className="latest-donations">
      { status === LOADING_STATUS ?
        skeleton
      : latestDonationsData.map( item => <LatestDonationItem key={ item.id } data={ item } /> ) }
    </div>
  )
});

export default LatestDonations;