// import utils
import { _t } from '../utils/i18n';

/**
 * Convert the table payment type keywords to the translatable text
 *
 * @param keyword | string
 * @return string
 */
export const getPaymentTypeByKeyword = keyword => {
  switch ( keyword ) {
    case 'recurring-donation':
      return _t( 'table_payment_type_recurring_donation' );
    case 'recurring-donation_init_web':
      return _t( 'table_payment_type_recurring_donation_init_web' );
    case 'recurring-donation_init_invoice':
      return _t( 'table_payment_type_recurring_donation_init_invoice' );
    case 'recurring-donation_interval_web':
      return _t( 'table_payment_type_recurring_donation_interval_web' );
    case 'recurring-donation_interval_invoice':
      return _t( 'table_payment_type_recurring_donation_interval_invoice' );
    case 'crowdfunding':
      return _t( 'table_payment_type_crowdfunding' );
    case 'iframe-donation':
      return _t( 'table_payment_type_iframe_donation' );
    case 'iframe-donation_init_web':
      return _t( 'table_payment_type_iframe_donation_init_web' );
    case 'iframe-donation_interval_web':
      return _t( 'table_payment_type_iframe_donation_interval_web' );
    case 'iframe-donation_init_invoice':
      return _t( 'table_payment_type_iframe_donation_init_invoice' );
    case 'iframe-donation_interval_invoice':
      return _t( 'table_payment_type_iframe_donation_interval_invoice' );
    default:
      return _t( 'table_payment_type_one_off_donation' );
  }
};