import React, { useEffect }         from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import constants
import { IDLE_STATUS }           from '../../constants/loadingStatuses';
import { CONTACTS_B2C_LOCATION } from '../../constants/componentLocation';

//import utils
import { _t } from '../../utils/i18n';

// import actions
import { fetchContactsB2C } from './api/contactsB2CSlice';

// import components
import ContactsContainerWrapper from '../../components/common/ContactsContainerWrapper/ContactsContainerWrapper';

export default function ContactsB2C() {
  // define dispatch
  const dispatch = useDispatch();

  // get the global state
  const { status, metaTitle } = useSelector( state => {
    const { contactsB2C: { status, metaTitle } } = state;

    return { status, metaTitle };
  });

  // fetch the landing data from API
  useEffect( () => { if ( status === IDLE_STATUS ) dispatch( fetchContactsB2C() ) }, [status, dispatch] );

  // define the fields to print, export to csv and copy
  const exportFields = ['id', 'salutation', 'first_name', 'last_name', 'street', 'street_nr', 'zip', 'city', 'country', 'email'];

  return <ContactsContainerWrapper metaTitle={ metaTitle }
                                   location={ CONTACTS_B2C_LOCATION }
                                   statsTitle={ _t( 'contacts_stats_b2c_title' ) }
                                   pageTitle
                                   pageTitleText={ _t( 'menu_b2c' ) }
                                   table
                                   tableTitle={ _t( 'contacts_b2c_table_title' ) }
                                   tableColumns={ 11 }
                                   tableSort="id"
                                   tableExportFields={ exportFields }
                                   popup />;
}