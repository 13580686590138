import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

// import icons
import { IconEdit } from '@tabler/icons-react';

// import constant
import { CONTACTS_POPUP_TRIGGER } from '../../../constants/dispatchTypes';

const TableEditContact = memo(( { row } ) => {
  const { id, salutation, customer_type, first_name, last_name, zip, city, street, street_nr, email, company_name } = row;

  // define dispatch
  const dispatch = useDispatch();

  /**
   * Processing opening a form to edit the contact data
   */
  const handleClick = () => {
    const payload = {
      isShown: true,
      contactType: customer_type,
      firstName: first_name,
      lastName: last_name,
      streetNr: street_nr,
      company: company_name,
      salutation, id, zip, city, street, email
    };

    dispatch( { type: CONTACTS_POPUP_TRIGGER, payload } );
  };

  return (
    <div className="table__actions-cell">
      <span className="table__actions-cell__icon"
            onClick={ () => handleClick() }>
        <IconEdit color="#444441" size={ 20 } />
      </span>
    </div>
  )
});

export default TableEditContact;