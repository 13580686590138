import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// import constants
import { API_URL } from '../../../constants/base';
import { IDLE_STATUS, LOADING_STATUS, SUCCEEDED_STATUS, FAILED_STATUS } from '../../../constants/loadingStatuses';
import {
  INVOICEPAYMENTS_FETCH, INVOICEPAYMENTS_DATE_UPDATE, TOAST_SHOW, RESEND_POPUP_HIDE
} from '../../../constants/dispatchTypes';

// import helpers
import { client }                     from '../../../api/client';
import { paymentMethodChartData }     from '../../../helpers/getPaymentMethod';
import { replaceTableKeywordsToText } from '../../../helpers/replaceTableKeywordsToText';
import { initialRequest, refreshTokenHelper, updateDateRequest } from '../../../api/helper';

// import utils
import { _t, setMetaTitle }                 from '../../../utils/i18n';
import { getAccessToken, isUserRemembered } from '../../../utils/Auth';

// import config
import { paymentMethodsTableConfig } from '../../../components/elements/Table/config';

// define API urls
const defaultUrl = 'dashboard_stats_api/get-all-payment-methods/?payment-method-category=qr-invoice';
const updateUrl  = 'dashboard_stats_api/get-all-payment-methods-update/?payment-method-category=qr-invoice';

/**
 * Fetching initial data request
 */
export const fetchInvoicePayments = createAsyncThunk(
  INVOICEPAYMENTS_FETCH,
  async ( args, thunkAPI ) =>
    initialRequest( args, thunkAPI, { defaultUrl, updateUrl }, true )
);

/**
 * Make request to update the data if the date range was changed
 *
 * @param dateRange | object
 * @param updatingData | object
 * @return function
 */
const updatingData = {
  defaultUrl,
  updateUrl,
  dispatchType: INVOICEPAYMENTS_DATE_UPDATE
};

export const updatingInvoicePayments = dateRange => updateDateRequest( dateRange, updatingData, true );

/**
 * Make request to resend the invoice
 *
 * @param orderNumber | string
 * @param paymentMethod | string
 * @return function
 */
export const resendInvoice = ( orderNumber, paymentMethod ) => {
  return async function resendInvoiceThunk( dispatch ) {
    try {
      const isRemembered = isUserRemembered();

      await refreshTokenHelper( 'update', isRemembered, dispatch );
      await client.post(
        API_URL + 'dashboard_stats_api/resend-invoice/',
        { invoice_type: paymentMethod, order_nr: orderNumber },
        { headers: { 'Authorization': "Bearer " + getAccessToken( isRemembered ) } }
      );

      dispatch( { type: RESEND_POPUP_HIDE, payload: false } );
      dispatch( { type: TOAST_SHOW, payload: { isShown: true, type: 'success', text: _t( 'invoice_sent_msg' ) } } );
    } catch ( err ) {
      dispatch( { type: TOAST_SHOW, payload: { isShown: true, type: 'danger', text: _t( 'fetch_error' ) } } );
      console.log( err );

      return Promise.reject( err );
    }
  }
};

const invoicePaymentsSlice = createSlice({
  name: 'invoicePayments',
  initialState: {
    status: IDLE_STATUS,
    tableStatus: IDLE_STATUS,
    metaTitle: '',
    topProductsChartData: [],
    tableData: [],
    tableOptions: paymentMethodsTableConfig(),
    error: _t( 'fetch_error' )
  },
  reducers: {
    statusUpdated: ( state, action ) => {
      const { payload } = action;

      state.status      = payload;
      state.tableStatus = payload;
    },
    dateUpdated: ( state, action ) => {
      const { topProductsChartData, tableData } = action.payload;

      state.topProductsChartData = topProductsChartData !== undefined ? paymentMethodChartData( topProductsChartData ) : state.topProductsChartData;
      state.tableData            = tableData !== undefined ? replaceTableKeywordsToText( tableData ) : state.tableData;
      state.status               = SUCCEEDED_STATUS;
      state.tableStatus          = SUCCEEDED_STATUS;
    },
  },
  extraReducers( builder ) {
    builder
      .addCase( fetchInvoicePayments.pending, state => {
        state.status      = LOADING_STATUS;
        state.tableStatus = LOADING_STATUS;
      })
      .addCase( fetchInvoicePayments.fulfilled, ( state, action ) => {
        const { topProductsChartData, tableData, metas } = action.payload;

        state.metaTitle            = metas !== undefined ? setMetaTitle( metas ) : state.metaTitle;
        state.topProductsChartData = topProductsChartData !== undefined ? paymentMethodChartData( topProductsChartData ) : state.topProductsChartData;
        state.tableData            = tableData !== undefined ? replaceTableKeywordsToText( tableData ) : state.tableData;
        state.status               = SUCCEEDED_STATUS;
        state.tableStatus          = SUCCEEDED_STATUS;
      })
      .addCase( fetchInvoicePayments.rejected, ( state, action ) => {
        state.status      = FAILED_STATUS;
        state.tableStatus = FAILED_STATUS;
        state.error       = action.error.message;

        console.log( action.error.message );
      })
  },
});

export const { statusUpdated: invoicePaymentsStatusUpdating } = invoicePaymentsSlice.actions;

export default invoicePaymentsSlice.reducer