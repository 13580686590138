// import constants
import { IDLE_STATUS } from '../constants/loadingStatuses';
import {
  RECURRING_DONATIONS_PATH, RECURRING_DONATIONS_INTERVAL_PATH, ALL_DONATIONS_PATH, ALL_PAYMENT_METHODS_PATH,
  ALL_PAYMENTS_PATH, CREDIT_CARD_METHOD_PATH, CROWDFUNDING_PATH, CRYPTO_METHOD_PATH, EWALLET_METHOD_PATH,
  INVOICE_METHOD_PATH, MERCHANDISE_PATH, PAYPAL_METHOD_PATH, POSTFINANCE_METHOD_PATH, SHOP_PATH, SINGLE_DONATIONS_PATH,
  TWINT_METHOD_PATH
} from '../constants/paths';
import {
  RECURRINGPAYMENTS_STATUS_UPDATED, RECURRINGPAYMENTS_INTERVAL_STATUS_UPDATED, ALLDONATIONSPAYMENTS_STATUS_UPDATED,
  ALLMETHODSPAYMENTS_STATUS_UPDATED, ALLPAYMENTS_STATUS_UPDATED, CCPAYMENTS_STATUS_UPDATED, CROWDPAYMENTS_STATUS_UPDATED,
  CRYPTOPAYMENTS_STATUS_UPDATED, EWALLETPAYMENTS_STATUS_UPDATED, INVOICEPAYMENTS_STATUS_UPDATED, LANDING_STATUS_UPDATED,
  MERCHPAYMENTS_STATUS_UPDATED, PAYPALPAYMENTS_STATUS_UPDATED, POSTFINPAYMENTS_STATUS_UPDATED, SHOPPAYMENTS_STATUS_UPDATED,
  SINGLEPAYMENTS_STATUS_UPDATED, TWINTPAYMENTS_STATUS_UPDATED
} from '../constants/dispatchTypes';

// import actions
import { hideToast } from '../components/elements/Toast/store/toastSlice';

/**
 * Hide error notification helper
 *
 * @param dispatch | function
 * @param toastIsShown | bool
 * @param prevUrl | string
 */
export const hideErrorNotification = ( dispatch, toastIsShown, prevUrl ) => {
  // hide toast
  if ( toastIsShown ) {
    dispatch( hideToast( false ) );

    // change page loading status if has error
    switch ( prevUrl ) {
      case RECURRING_DONATIONS_PATH:
        dispatch( { type: RECURRINGPAYMENTS_STATUS_UPDATED, payload: IDLE_STATUS } );
        break;
      case RECURRING_DONATIONS_INTERVAL_PATH:
        dispatch( { type: RECURRINGPAYMENTS_INTERVAL_STATUS_UPDATED, payload: IDLE_STATUS } );
        break;
      case ALL_DONATIONS_PATH:
        dispatch( { type: ALLDONATIONSPAYMENTS_STATUS_UPDATED, payload: IDLE_STATUS } );
        break;
      case ALL_PAYMENT_METHODS_PATH:
        dispatch( { type: ALLMETHODSPAYMENTS_STATUS_UPDATED, payload: IDLE_STATUS } );
        break;
      case ALL_PAYMENTS_PATH:
        dispatch( { type: ALLPAYMENTS_STATUS_UPDATED, payload: IDLE_STATUS } );
        break;
      case CREDIT_CARD_METHOD_PATH:
        dispatch( { type: CCPAYMENTS_STATUS_UPDATED, payload: IDLE_STATUS } );
        break;
      case CROWDFUNDING_PATH:
        dispatch( { type: CROWDPAYMENTS_STATUS_UPDATED, payload: IDLE_STATUS } );
        break;
      case CRYPTO_METHOD_PATH:
        dispatch( { type: CRYPTOPAYMENTS_STATUS_UPDATED, payload: IDLE_STATUS } );
        break;
      case EWALLET_METHOD_PATH:
        dispatch( { type: EWALLETPAYMENTS_STATUS_UPDATED, payload: IDLE_STATUS } );
        break;
      case INVOICE_METHOD_PATH:
        dispatch( { type: INVOICEPAYMENTS_STATUS_UPDATED, payload: IDLE_STATUS } );
        break;
      case MERCHANDISE_PATH:
        dispatch( { type: MERCHPAYMENTS_STATUS_UPDATED, payload: IDLE_STATUS } );
        break;
      case PAYPAL_METHOD_PATH:
        dispatch( { type: PAYPALPAYMENTS_STATUS_UPDATED, payload: IDLE_STATUS } );
        break;
      case POSTFINANCE_METHOD_PATH:
        dispatch( { type: POSTFINPAYMENTS_STATUS_UPDATED, payload: IDLE_STATUS } );
        break;
      case SHOP_PATH:
        dispatch( { type: SHOPPAYMENTS_STATUS_UPDATED, payload: IDLE_STATUS } );
        break;
      case SINGLE_DONATIONS_PATH:
        dispatch( { type: SINGLEPAYMENTS_STATUS_UPDATED, payload: IDLE_STATUS } );
        break;
      case TWINT_METHOD_PATH:
        dispatch( { type: TWINTPAYMENTS_STATUS_UPDATED, payload: IDLE_STATUS } );
        break;
      default:
        dispatch( { type: LANDING_STATUS_UPDATED, payload: IDLE_STATUS } );
        break;
    }
  }
};