import React, { memo } from 'react';

// import components
import SkeletonLine from '../../common/Skeleton/SkeletonLine';

const CrowdfundingProjectItemSkeleton = memo( () => {
  return (
    <div className="crowdfunding-project-item__skeleton-item">
      <div className="crowdfunding-project-item__skeleton-item__name--wrapper">
        <SkeletonLine className="crowdfunding-project-item__skeleton-item__name--top" />
        <SkeletonLine className="crowdfunding-project-item__skeleton-item__name--bottom" />
      </div>
      <div className="crowdfunding-project-item__skeleton-item__amount--wrapper">
        <SkeletonLine className="crowdfunding-project-item__skeleton-item__amount" />
      </div>
      <div className="crowdfunding-project-item__skeleton-item__current--wrapper">
        <SkeletonLine className="crowdfunding-project-item__skeleton-item__current" />
      </div>
      <div className="crowdfunding-project-item__skeleton-item__goal--wrapper">
        <SkeletonLine className="crowdfunding-project-item__skeleton-item__goal" />
      </div>
      <div className="crowdfunding-project-item__skeleton-item__progress--wrapper">
        <SkeletonLine className="crowdfunding-project-item__skeleton-item__progress--top" />
        <SkeletonLine className="crowdfunding-project-item__skeleton-item__progress--bottom" />
      </div>
      <div className="crowdfunding-project-item__skeleton-item__status--wrapper">
        <SkeletonLine className="crowdfunding-project-item__skeleton-item__status" />
      </div>
      <div className="crowdfunding-project-item__skeleton-item__deadline--wrapper">
        <SkeletonLine className="crowdfunding-project-item__skeleton-item__deadline" />
      </div>
    </div>
  )
});

export default CrowdfundingProjectItemSkeleton;