import React, { memo } from 'react';
import { useSelector } from 'react-redux';

// import constants
import { SUCCEEDED_STATUS } from '../../../constants/loadingStatuses';
import {
  ALL_PAYMENTS_LOCATION, ALL_DONATIONS_LOCATION, RECURRING_DONATIONS_LOCATION, SINGLE_DONATIONS_LOCATION,
  CROWDFUNDING_LOCATION, MERCHANDISE_LOCATION, SHOP_LOCATION, CREDIT_CARD_PAYMENTS_LOCATION, EWALLET_PAYMENTS_LOCATION,
  TWINT_PAYMENTS_LOCATION, POSTFINANCE_PAYMENTS_LOCATION, CRYPTO_PAYMENTS_LOCATION, INVOICE_PAYMENTS_LOCATION,
  PAYPAL_PAYMENTS_LOCATION, RECURRING_DONATIONS_INTERVAL_LOCATION
} from '../../../constants/componentLocation';

// import components
import TopStatsItem         from './TopStatsItem';
import TopStatsItemSkeleton from './TopStatsItemSkeleton';

const TopStats = memo( ( { location } ) => {
  // get state related to location
  const { status, topStatsData } = useSelector( state => {
    switch( location ) {
      case ALL_PAYMENTS_LOCATION:
        return state.allPayments;
      case ALL_DONATIONS_LOCATION:
        return state.allDonations;
      case RECURRING_DONATIONS_LOCATION:
        return state.recurringDonations;
      case RECURRING_DONATIONS_INTERVAL_LOCATION:
        return state.recurringDonationsInterval;
      case SINGLE_DONATIONS_LOCATION:
        return state.singleDonations;
      case CROWDFUNDING_LOCATION:
        return state.crowdfunding;
      case MERCHANDISE_LOCATION:
        return state.merchandise;
      case SHOP_LOCATION:
        return state.shop;
      case CREDIT_CARD_PAYMENTS_LOCATION:
        return state.creditCardPayments;
      case EWALLET_PAYMENTS_LOCATION:
        return state.ewalletPayments;
      case TWINT_PAYMENTS_LOCATION:
        return state.twintPayments;
      case POSTFINANCE_PAYMENTS_LOCATION:
        return state.postfinancePayments;
      case CRYPTO_PAYMENTS_LOCATION:
        return state.cryptoPayments;
      case PAYPAL_PAYMENTS_LOCATION:
        return state.paypalPayments;
      case INVOICE_PAYMENTS_LOCATION:
        return state.invoicePayments;
      /* falls through */
      default:
        return state.landing;
    }
  });

  // define skeleton
  const skeleton = Array.from({ length: 6 }, ( _, index ) => {
    return (
      <div className="col col-xs-4" key={ index }>
        <TopStatsItemSkeleton />
      </div>
    );
  });

  return (
    <div className="top-stats">
      <div className="row">
        { status === SUCCEEDED_STATUS ?
          topStatsData.map( item => {
            return (
              <div className="col col-xs-4" key={ item.id }>
                <TopStatsItem data={ item } />
              </div>
            )
          })
        : skeleton }
      </div>
    </div>
  )
});

export default TopStats;