import React                        from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import actions
import { collapseSidebar } from '../../common/Sidebar/store/sidebarSlice';

export default function SidebarExtend() {
  // get the global state
  const { isCollapsed, theme } = useSelector( state => state.sidebar );

  // define dispatch
  const dispatch = useDispatch();

  // define the icon color
  const iconColor = theme === 'dark' ? '#fff' : '#444441';

  return (
    <svg className="sidebar-toggle sidebar-toggle--toolbar"
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         onClick={ () => dispatch( collapseSidebar( !isCollapsed ) ) }>
      <g clipPath="url(#clip0_740_5199)">
        <path d="M20 6H4" stroke={ iconColor } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 12L4 12" stroke={ iconColor } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20 18H4" stroke={ iconColor } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 9L20 12L17 15" stroke={ iconColor } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_740_5199">
          <rect width="24" height="24" fill={ iconColor } transform="matrix(-1 0 0 1 24 0)"/>
        </clipPath>
      </defs>
    </svg>
  )
}