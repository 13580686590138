import React                        from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import actions
import { collapseSidebar } from '../../common/Sidebar/store/sidebarSlice';

export default function SidebarCollapse() {
  // get the global state
  const { isCollapsed, theme } = useSelector( state => state.sidebar );

  // define dispatch
  const dispatch = useDispatch();

  // define the icon color
  const iconColor = theme === 'dark' ? '#fff' : '#444441';

  return (
    <svg className="sidebar-toggle"
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         onClick={ () => dispatch( collapseSidebar( !isCollapsed ) ) }>
      <g clipPath="url(#clip0_740_4560)">
        <path d="M4 6H20" stroke={ iconColor } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 12L20 12" stroke={ iconColor } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4 18H20" stroke={ iconColor } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 9L4 12L7 15" stroke={ iconColor } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_740_4560">
          <rect width="24" height="24" fill={ iconColor } />
        </clipPath>
      </defs>
    </svg>
  )
}