import React, { useEffect }         from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import constants
import { CONTACTS_B2C_PATH, CONTACTS_B2B_PATH, CONTACTS_NL_SUBSCRIPTION_PATH } from '../../../constants/paths';

// import utils
import { _t } from '../../../utils/i18n';

// import actions
import { changeSection } from '../../common/Sidebar/store/sidebarSlice';

// import icons
import { IconHexagons, IconBrandMessenger, IconCoins, IconUser } from '@tabler/icons-react';

// import components
import Logo                    from '../Logo/Logo';
import SidebarToolsItem        from './SidebarToolsItem';
import SidebarToolsItemSupport from './SidebarToolsItemSupport';
import SidebarToolsItemProfile from './SidebarToolsItemProfile';

export default function SidebarTools() {
  // get the global state
  const { theme } = useSelector( state => {
    const { sidebar: { theme } } = state;

    return { theme };
  });

  // define dispatch
  const dispatch = useDispatch();

  const pathname = window.location.pathname;

  useEffect( () => {
    if (
      pathname === CONTACTS_B2C_PATH ||
      pathname === CONTACTS_B2B_PATH ||
      pathname === CONTACTS_NL_SUBSCRIPTION_PATH
    ) {
      const data = {
        activeSection: 'contacts',
        sectionTitle: _t( 'menu_title_contacts' )
      };

      dispatch( changeSection( data ) );
    }
  }, [pathname] );

  // define icons color
  const iconColor = theme === 'dark' ? '#fff' : '#444441';

  return (
    <section className="sidebar-tools">
      <Logo />
      <div className="sidebar-tools__list">
        <div className="sidebar-tools__list__top">
          <SidebarToolsItem type="platform"
                            text={ _t( 'menu_title_platform' ) }>
            <IconHexagons color={ iconColor } />
          </SidebarToolsItem>

          <SidebarToolsItem type="automation"
                            text={ _t( 'menu_title_automation' ) }>
            <IconBrandMessenger color={ iconColor } />
          </SidebarToolsItem>

          <SidebarToolsItem type="dashboard"
                            text={ _t( 'menu_title_dashboard' ) }>
            <IconCoins color={ iconColor } />
          </SidebarToolsItem>

          <SidebarToolsItem type="contacts"
                            text={ _t( 'menu_title_contacts' ) }>
            <IconUser color={ iconColor } />
          </SidebarToolsItem>
        </div>

        <div className="sidebar-tools__list__bottom">
          <SidebarToolsItemSupport />
          <SidebarToolsItemProfile />
        </div>
      </div>
    </section>
  );
}