import React, { memo } from 'react';
import { useSelector } from 'react-redux';

// import constants
import { SUCCEEDED_STATUS } from '../../../constants/loadingStatuses';
import { CONTACTS_B2B_LOCATION, CONTACTS_SUBSCRIPTION_LOCATION } from '../../../constants/componentLocation';

// import helper
import { swissNumberFormat } from '../../../helpers/swissNumberFormat';

// import components
import TopStatsContactsSkeleton from './TopStatsContactsSkeleton';

const TopStatsContacts = memo( ( { location, statsTitle } ) => {
  // get state related to location
  const { status, tableData } = useSelector( state => {
    switch( location ) {
      case CONTACTS_B2B_LOCATION:
        return state.contactsB2B;
      case CONTACTS_SUBSCRIPTION_LOCATION:
        return state.contactsSubscription;
      /* falls through */
      default:
        return state.contactsB2C;
    }
  });

  return (
    <div className="top-stats">
      <div className="row">
        { status === SUCCEEDED_STATUS ?
          <div className="col col-xs-4">
            <div className="top-stats__item">
              <span className="top-stats__item__title">{ statsTitle }</span>
              <div className="top-stats__item__statistics">
                <div className="top-stats__item__statistics__left">
                  <p className="top-stats__item__amount">{ swissNumberFormat( tableData.length ) }</p>
                </div>
              </div>
            </div>
          </div>
        :
          <div className="col col-xs-4">
            <TopStatsContactsSkeleton />
          </div>
        }
      </div>
    </div>
  )
});

export default TopStatsContacts;