import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

// import constants
import { INVOICE_PAYMENT_METHOD }  from '../../../constants/base';
import { CONFIRMATION_POPUP_SHOW } from '../../../constants/dispatchTypes';

// import utils
import { _t } from '../../../utils/i18n';

// import icons
import { IconEdit } from '@tabler/icons-react';

const TableActionInactive = memo( ({ data }) => {
  const { identifier, order_nr, payment_method } = data;

  // define dispatch
  const dispatch = useDispatch();

  const handleClick = () => {
    const payload = {
      isShown: true,
      id: identifier,
      number: order_nr,
      payment_method: payment_method === INVOICE_PAYMENT_METHOD ? 'invoice' : 'online'
    };

    dispatch( { type: CONFIRMATION_POPUP_SHOW, payload } );
  };

  return (
    <span className="table__actions-cell__icon"
          title={ _t( 'table_inactive_tooltip' ) }
          onClick={ () => handleClick() }>
      <IconEdit color="#444441" size={ 20 }/>
    </span>
  );
});

export default TableActionInactive;