import React, { memo, useEffect }   from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import icons
import { IconX, IconCheck, IconInfoCircle, IconAlertCircle, IconAlertTriangle } from '@tabler/icons-react';

// import actions
import { hideToast } from './store/toastSlice';

const Toast = memo( () => {
  // get the global state
  const { isShown, type, text } = useSelector( state => state.toast );

  // define dispatch
  const dispatch = useDispatch();

  // automatically hide toast
  useEffect( () => {
    if ( isShown && type !== 'danger' ) {
      setTimeout( () =>
        dispatch( hideToast( !isShown ) ), 5000 );
    }
  }, [isShown] );

  const iconsColor = '#fff';

  return (
    <div className={ isShown ? `toast toast--is-visible toast--${ type }` : `toast toast--${ type }` }>
      <div className="toast__content">
        <div className="toast__icon">
          { type === 'success' ? <IconCheck color={ iconsColor } size={ 48 } />: false }
          { type === 'info' ? <IconInfoCircle color={ iconsColor } size={ 48 } />: false }
          { type === 'warning' ? <IconAlertCircle color={ iconsColor } size={ 48 } />: false }
          { type === 'danger' ? <IconAlertTriangle color={ iconsColor } size={ 48 } />: false }
        </div>
        <p className="toast__text">{ text }</p>
      </div>
      <span className="toast__close"
            onClick={ () => dispatch( hideToast( !isShown ) ) }>
        <IconX color={ iconsColor } />
      </span>
    </div>
  )
});

export default Toast;