import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

// import constant
import { RESEND_POPUP_SHOW } from '../../../constants/dispatchTypes';

// import utils
import { _t } from '../../../utils/i18n';

// import icons
import { IconCash } from '@tabler/icons-react';

const TableActionResend = memo( ({ data }) => {
  const { order_nr, resend_invoice_type } = data;

  // define dispatch
  const dispatch = useDispatch();

  /**
   * Processing to re-send an invoice
   */
  const handleClick = () => {
    const payload = {
      isShown: true,
      orderNumber: order_nr,
      paymentMethod: resend_invoice_type
    };

    dispatch( { type: RESEND_POPUP_SHOW, payload } );
  };

  return (
    <span className="table__actions-cell__icon"
          title={ _t( 'table_resend_tooltip' ) }
          onClick={ () => handleClick() }>
      <IconCash color="#444441" size={ 20 }/>
    </span>
  );
});

export default TableActionResend;