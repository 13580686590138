export async function client( endpoint, { body, ...customConfig } = {} ) {
  const headers = { 'Content-Type': 'application/json' };

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if ( body ) config.body = JSON.stringify( body );

  let data;

  try {
    const response = await window.fetch( endpoint, config );
    const { status, statusText } = response;

    data = await response.json();

    if ( status === 200 || status === 201 ) {
      return {
        status,
        statusText,
        headers: response.headers,
        url: response.url,
        data,
      }
    }

    if ( status === 401 || status === 500 ) {
      return {
        status,
        statusText,
        data,
      }
    }

    throw new Error( statusText )
  } catch ( err ) {
    // TODO: add error handler
    console.log( err );
    return Promise.reject( err.message ? err.message : data );
  }
}

client.get = function ( endpoint, customConfig = {} ) {
  return client( endpoint, { ...customConfig, method: 'GET' } )
};

client.post = function ( endpoint, body, customConfig = {} ) {
  return client( endpoint, { ...customConfig, body } )
};
