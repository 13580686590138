/**
 * A regular expression to validate an email value
 *
 * @param email | string
 *
 * @return boolean
 */
export const emailValidator = email => {
  const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regExp.test( email );
};

/**
 * A regular expression to validate the first name and last name values
 *
 * @param name | string
 *
 * @return boolean
 */
export const nameValidator = name => {
  const regExp = /^[A-Za-zŽžÀ-ÿ][A-Za-zŽžÀ-ÿ\'\-]+([\ A-Za-zŽžÀ-ÿ][A-Za-zŽžÀ-ÿ\'\-]+)*/;

  return regExp.test( name );
};

/**
 * A regular expression to validate a zip code value
 *
 * @param zip | string
 *
 * @return boolean
 */
export const zipValidator = zip => {
  const regExp = /^[0-9]{4,5}$/;

  return regExp.test( zip );
};

/**
 * A regular expression to validate a city value
 *
 * @param city | string
 *
 * @return boolean
 */
export const cityValidator = city => {
  const regExp = /[A-Za-z\u00C0-\u024F]{2,}$/g;

  return regExp.test( city );
};

/**
 * A regular expression to validate a street value
 *
 * @param street | string
 *
 * @return boolean
 */
export const streetValidator = street => {
  const regExp = /((^[A-Za-z\u00C0-\u024F]+(['|\-|\s.+]{0,}[A-Za-z\u00C0-\u024F.]+)*)+)$/;

  return regExp.test( street );
};

/**
 * A regular expression to validate a street number value
 *
 * @param number | string
 *
 * @return boolean
 */
export const streetNumberValidator = number => {
  const regExp = /^[0-9]{1,}$/;

  return regExp.test( number );
};