// import utils
import { _t } from '../utils/i18n';

/**
 * Convert the table gender keywords to the translatable text
 *
 * @param keyword | string
 * @return string
 */
export const getGenderByKeyword = keyword => {
  switch ( keyword ) {
    case 'Male':
      return _t( 'contacts_form_gender_male' );
    case 'Female':
      return _t( 'contacts_form_gender_female' );
    default:
      return _t( 'table_no_gender' );
  }
};

/**
 * Convert the table gender value to the system keyword
 *
 * @param value | string
 * @return string
 */
export const convertGenderToValue = value => {
  switch ( value ) {
    case _t( 'contacts_form_gender_male' ):
      return 'Male';
    case _t( 'contacts_form_gender_female' ):
      return 'Female';
    default:
      return 'noGender';
  }
};