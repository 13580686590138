import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// import constants
import { API_URL, CONTACTS_PRIVATE } from '../../../constants/base';
import { IDLE_STATUS, LOADING_STATUS, SUCCEEDED_STATUS, FAILED_STATUS } from '../../../constants/loadingStatuses';
import {
  CONTACTS_B2C_FETCH, CONTACTS_POPUP_TRIGGER, TOAST_SHOW, CONTACTS_UPDATED_B2C, CONTACTS_UPDATED_B2B
} from '../../../constants/dispatchTypes';

// import helpers
import { client } from '../../../api/client';
import { replaceTableKeywordsToText }          from '../../../helpers/replaceTableKeywordsToText';
import { convertGenderToValue }                from '../../../helpers/getGenderByKeyword';
import { contactsRequest, refreshTokenHelper } from '../../../api/helper';

// import utils
import { _t } from '../../../utils/i18n';
import { getAccessToken, isUserRemembered } from '../../../utils/Auth';

// import components
import TableEditContact from '../../../components/elements/Table/TableEditContact';

// define API urls
const defaultUrl = 'customer-api/get-customers?type=b2c';

// define table
const tableOptions = [
  {
    id: 'actions',
    name: _t( 'table_actions' ),
    selector: row => <TableEditContact row={ row } />,
    width: '65px',
  },
  {
    id: 'id',
    name: _t( 'table_order_num' ),
    selector: row => row.id,
    sortable: true,
    width: '65px',
  },
  {
    id: 'gender',
    name: _t( 'table_gender' ),
    selector: row => row.salutation,
    width: '85px',
  },
  {
    id: 'first-name',
    name: _t( 'table_first_name' ),
    selector: row => row.first_name,
    sortable: true,
  },
  {
    id: 'last-name',
    name: _t( 'table_last_name' ),
    selector: row => row.last_name,
  },
  {
    id: 'street',
    name: _t( 'table_street' ),
    selector: row => row.street,
  },
  {
    id: 'street-nr',
    name: _t( 'table_street_nr' ),
    selector: row => row.street_nr,
    width: '65px',
  },
  {
    id: 'zip',
    name: _t( 'table_zip' ),
    selector: row => row.zip,
    width: '100px',
  },
  {
    id: 'city',
    name: _t( 'table_city' ),
      selector: row => row.city,
  },
  {
    id: 'country',
    name: _t( 'table_country' ),
    selector: row => row.country,
    width: '65px',
  },
  {
    id: 'email',
    name: _t( 'table_email' ),
    selector: row => row.email,
  },
];

/**
 * Fetching initial data request
 *
 * @return object
 */
export const fetchContactsB2C = createAsyncThunk(
  CONTACTS_B2C_FETCH,
  async ( args, thunkAPI ) =>
  contactsRequest( args, thunkAPI, { defaultUrl } )
);

/**
 * Make request to update the contact
 *
 * @param data | object
 * @param tableData | array
 * @return function
 */
export const updateContact = ( data, tableData ) => {
  return async function updateContactThunk( dispatch ) {
    try {
      const { contactType, firstName, lastName, zip, city, street, streetNr, email, id, salutation, company } = data;
      const isRemembered = isUserRemembered();

      await refreshTokenHelper( 'update', isRemembered, dispatch );

      const body = {
        first_name: firstName,
        last_name: lastName,
        street_nr: streetNr,
        company_name: company,
        street,
        zip,
        city,
        email,
        salutation: convertGenderToValue( salutation ),
      };

      await client.post(
        API_URL + 'customer-api/update-customer/' + id,
        body,
        { headers: { 'Authorization': "Bearer " + getAccessToken( isRemembered ) } }
      );

      const payload = {
        isShown    : false,
        contactType: '',
        firstName  : '',
        lastName   : '',
        zip        : '',
        city       : '',
        street     : '',
        streetNr   : '',
        email      : '',
        id         : '',
        salutation : '',
        company    : '',
      };

      const updatedTableData = tableData.map( item => {
        if ( item.id === id ) {
          return { ...item, firstName, lastName, zip, city, street, streetNr, email, id, salutation, company };
        } else return item;
      });

      dispatch( { type: contactType === CONTACTS_PRIVATE ? CONTACTS_UPDATED_B2C : CONTACTS_UPDATED_B2B, payload: updatedTableData } );
      dispatch( { type: CONTACTS_POPUP_TRIGGER, payload } );
      dispatch( { type: TOAST_SHOW, payload: { isShown: true, type: 'success', text: _t( 'contacts_updated_msg' ) } } );
    } catch ( err ) {
      dispatch( { type: TOAST_SHOW, payload: { isShown: true, type: 'danger', text: _t( 'fetch_error' ) } } );
      console.log( err );

      return Promise.reject( err );
    }
  }
};

const contactsB2CSlice = createSlice({
  name: 'contactsB2C',
  initialState: {
    status: IDLE_STATUS,
    tableStatus: IDLE_STATUS,
    metaTitle: 'B2C Contacts | Soulclick Manager',
    tableData: [],
    tableOptions,
    error: _t( 'fetch_error' )
  },
  reducers: {
    updateTable: ( state, action ) => {
      const { payload } = action;

      state.status      = IDLE_STATUS;
      state.tableStatus = IDLE_STATUS;
      state.tableData   = payload !== undefined ? replaceTableKeywordsToText( payload ) : state.tableData;
    }
  },
  extraReducers( builder ) {
    builder
      .addCase( fetchContactsB2C.pending, state => {
        state.status = LOADING_STATUS;
      })
      .addCase( fetchContactsB2C.fulfilled, ( state, action ) => {
        const { payload: tableData } = action;

        state.tableData   = tableData !== undefined ? replaceTableKeywordsToText( tableData ) : state.tableData;
        state.status      = SUCCEEDED_STATUS;
        state.tableStatus = SUCCEEDED_STATUS;
      })
      .addCase( fetchContactsB2C.rejected, ( state, action ) => {
        state.status      = FAILED_STATUS;
        state.tableStatus = FAILED_STATUS;
        state.error       = action.error.message;

        console.log( action.error.message );
      })
  },
});

export default contactsB2CSlice.reducer;