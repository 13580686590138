import React, { memo } from 'react';

// import components
import SkeletonLine from '../../common/Skeleton/SkeletonLine';

const TopStatsContactsSkeleton = memo( () => {
  return (
    <div className="top-stats__skeleton-item top-stats__skeleton-item--contacts">
      <SkeletonLine className="top-stats__skeleton-item__top" />
      <div className="top-stats__skeleton-item__bottom top-stats__skeleton-item__bottom--contacts">
        <div className="top-stats__skeleton-item__bottom__left">
          <SkeletonLine className="top-stats__skeleton-item__bottom__left__top" />
        </div>
      </div>
    </div>
  )
});

export default TopStatsContactsSkeleton;