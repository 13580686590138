import React                        from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import actions
import { changeRemember } from './store/loginSlice';

// import utils
import { _t_byBrowserLang } from '../../../utils/i18n';

// import icons
import { IconCheck } from '@tabler/icons-react';

export default function LoginRemember() {
  // get the global state
  const { isRemembered } = useSelector( state => state.login );

  // define dispatch
  const dispatch = useDispatch();

  return (
    <div className="login-remember"
         onClick={ () => dispatch( changeRemember( !isRemembered ) ) }>
      <div className="login-remember__check">
        <IconCheck className={ !isRemembered ? 'login-remember__check__icon' : 'login-remember__check__icon login-remember__check__icon--is-visible' }
                   color="#9CA8BA" />
      </div>
      <span className="login-remember__label">{ _t_byBrowserLang( 'login_remember_label' ) }</span>
    </div>
  )
}