import React                        from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { NavLink }                  from 'react-router-dom';

// import utils
import { _t } from '../../../utils/i18n';

// import actions
//import { changeSection } from '../../common/Sidebar/store/sidebarSlice';
import { collapseSidebar } from '../../common/Sidebar/store/sidebarSlice';

// import utils
import { getUser } from '../../../utils/Auth';

export default function SidebarToolsItemProfile() {
  // get the global state
  const { isCollapsed, isRemembered } = useSelector( state => {
    const {
      sidebar: { isCollapsed },
      login: { isRemembered },
    } = state;

    return { isCollapsed, isRemembered };
  });

  // get the user information
  const { userName, avatar } = getUser( isRemembered );

  // define dispatch
  const dispatch = useDispatch();

  /**
   * Processing click on a link
   */
  const handleClick = () => {
    /*const data = {
      activeSection: 'support',
      sectionTitle: ''
    };

    dispatch( changeSection( data ) );*/

    if ( isCollapsed ) {
      dispatch( collapseSidebar( !isCollapsed ) );
    }
  };

  return (
    <div className="sidebar-tools__profile"
         title={ _t( 'menu_disabled_msg' ) }
         onClick={ () => handleClick() }>
      <img className="sidebar-tools__profile__img"
           src={ avatar }
           alt={ userName } />
      { isCollapsed ?
        <span className="sidebar-tools__item__tooltip">{ userName }</span>
      : false }
    </div>
  );
}