import React, { memo } from 'react';
import { useSelector } from 'react-redux';

// import constants
import { LOADING_STATUS } from '../../../constants/loadingStatuses';

const TotalAmountItem = memo( ( { data } ) => {
  // get state
  const { status } = useSelector( state => state.landing );

  // get props
  const { productType, percent, amount } = data;

  return (
    <div className="total-amount-item">
      <div className="total-amount-item__progress">
        <div className="total-amount-item__progress__line">
          <span className="total-amount-item__progress__fill"
                style={{ width: status === LOADING_STATUS ? '0' : percent + '%' }} />
        </div>
      </div>
      <div className="total-amount-item__data">
        <h5 className="total-amount-item__title">{ productType }</h5>
        <span className="total-amount-item__amount">{ amount }</span>
      </div>
    </div>
  )
});

export default TotalAmountItem;