import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart  from 'react-apexcharts';

// import constants
import { SUCCEEDED_STATUS } from '../../../constants/loadingStatuses';

// import components
import ChartSkeleton from './ChartSkeleton';

// import the chart config
import { chartConfig } from './config';

const Chart = memo( ( { chartType = 'area' } ) => {
  // get the global state
  const { status, transactionsChartData } = useSelector( state => state.landing );

  // define the chart options
  const options = chartConfig( transactionsChartData, chartType );

  return (
    <div className="transaction-chart">
      { status === SUCCEEDED_STATUS ?
        <ReactApexChart options={ options }
                        series={ options.series }
                        type={ chartType }
                        width="100%"
                        height="350" />
      : <ChartSkeleton /> }
    </div>
  )
});

export default Chart;