import React, { memo, useEffect, useState } from 'react';

/**
 * Input field component
 *
 * @param styleType | string. Available values: medium, small
 * @param inputType | string. Available values: text, password
 * @param value | string
 * @param isDisabled | bool
 * @param isRequired | bool
 * @param isError | bool
 * @param id | string
 * @param name | string
 * @param placeholder | string
 * @param errorMsgTxt | string
 * @param onChange | function
 * @param onFocus | function
 */
const Input = memo((
  { styleType = 'medium',
    inputType = 'text',
    value = '',
    isDisabled = false,
    isRequired = false,
    isError = false,
    id, name, placeholder, errorMsgTxt, onChange, onFocus
  } ) => {
  const [val, setVal] = useState( '' );

  useEffect( () => setVal( value ), [value] );

  const handleChange = e => {
    const value = e.target.value;

    setVal( value );
    onChange( { name, value } );
  };

  return (
    <div className="input">
      <input className={ `input__field input__field--type-${ styleType }${ isError ? ' input__field--is-error' : '' }` }
             id={ id }
             type={ inputType }
             name={ name }
             value={ val }
             placeholder=" "
             disabled={ isDisabled }
             onChange={ e => handleChange( e ) }
             onFocus={ () => onFocus( name ) } />
      <label className="input__label"
             htmlFor={ id }>{ placeholder }</label>
      { isRequired ?
        <span className={ `input__msg input__msg--is-error${ !isError ? ' input__msg--is-hidden' : '' }` }>
          { errorMsgTxt }
        </span>
      : false }
    </div>
  )
});

export default Input;